import React, { useContext, useEffect, useState } from 'react';
import { Fab, Fade, Tooltip } from '@mui/material';
import SentimentSatisfiedAlt from '@mui/icons-material/SentimentSatisfiedAlt';
import { Box } from '@mui/system';
import { AppContext, AppContextType } from "../../context/AppContextProvider";
import {useTranslation} from "react-i18next";

const FeedbackButton: React.FC = () => {
    const { updateContext }: AppContextType = useContext(AppContext);
    const {t} = useTranslation();
    const [shaking, setShaking] = useState(false);

    const handleClick = () => {
        updateContext({
            feedbackDialogIsOpen: true
        });
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setShaking(true);
            setTimeout(() => setShaking(false), 2000);
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 16,
                right: 16,
                zIndex: 1000,
                animation: shaking ? 'shake 1s' : 'none',
                '@keyframes shake': {
                    '0%': { transform: 'translate(0, 0)' },
                    '10%': { transform: 'translate(-3px, -3px)' },
                    '20%': { transform: 'translate(3px, -3px)' },
                    '30%': { transform: 'translate(-3px, 3px)' },
                    '40%': { transform: 'translate(3px, 3px)' },
                    '50%': { transform: 'translate(-5px, -5px)' },
                    '60%': { transform: 'translate(5px, -5px)' },
                    '70%': { transform: 'translate(-5px, 5px)' },
                    '80%': { transform: 'translate(5px, 5px)' },
                    '90%': { transform: 'translate(0px, 0px)' },
                    '100%': { transform: 'translate(0, 0)' },
                },
            }}
        >
            <Tooltip
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                title={t('feedback.hoverFeedback')}
                placement={'left'}
            >
                <Fab
                    color="secondary"
                    size="medium"
                    onClick={handleClick}
                    sx={{
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
                        ':hover': {
                            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.4)',
                        },
                    }}
                >
                    <SentimentSatisfiedAlt/>
                </Fab>
            </Tooltip>
        </Box>
    );
};

export default FeedbackButton;

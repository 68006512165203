import React, {ReactNode} from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {SuccessImage} from "../../Pyment/Image/SuccessImage";
import Main from "../../../layouts/Main";
import Container from "../../../components/Container";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";


const OfferCreatedSuccessfully = (): ReactNode => {
    const {t} = useTranslation()
    const navigate = useNavigate();

    const handleClickToMainPage = () => {
        navigate('/');
    };

    return (
        <Main colorInvert={true}>
            <Box bgcolor={'alternate.main'} paddingTop={1}>
                <Container>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Box
                            data-aos={'fade-up'}
                            marginBottom={{xs: 2, md: 4}}
                        >
                            {SuccessImage}
                        </Box>
                        <Box>
                            <Typography
                                variant="h4"
                                align={'center'}
                                data-aos={'fade-up'}
                                gutterBottom
                                sx={{
                                    fontWeight: 700,
                                }}
                            >
                                {t('offerCreated.title')}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                align={'center'}
                                data-aos={'fade-up'}
                                gutterBottom
                            >
                                {t('offerCreated.subtitle')}
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection={{xs: 'column', md: 'row'}}
                            marginTop={{xs: 2, md: 5}}
                        >
                            <Button
                                sx={{height: 54, whiteSpace: 'nowrap'}}
                                variant="contained"
                                color="primary"
                                size="medium"
                                fullWidth
                                type={'submit'}
                                onClick={handleClickToMainPage}
                            >
                                {t('common.backToMainPage')}
                            </Button>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Main>
    );
};

export default OfferCreatedSuccessfully;

import React, {ReactNode} from 'react';
import Box from "@mui/material/Box";
import {IconButton} from "@mui/material";
import LinkedIn from '@mui/icons-material/LinkedIn';
import Instagram from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';

const SocialNetworks = (): ReactNode => {
    return (
        <Box
            display={'flex'}
        >
            <Box>
                <IconButton size={'small'} href={'https://www.facebook.com/profile.php?id=61558287193709'}>
                    <FacebookIcon />
                </IconButton>
                <IconButton size={'small'} href={'https://www.instagram.com/parkio.findaparkspot/'}>
                    <Instagram />
                </IconButton>
                <IconButton size={'small'} href={'https://www.linkedin.com/company/parkio-co'}>
                    <LinkedIn />
                </IconButton>
            </Box>
        </Box>
    );
};

export default SocialNetworks;

/* eslint-disable react/no-unescaped-entities */
import React, {ReactNode} from 'react';
import {alpha, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Container from '../../../../components/Container';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import {scrollToTarget} from "../../../../utils/Scroll";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";

const Hero = (): ReactNode => {
    const {t} = useTranslation()
    const theme = useTheme();
    const navigate = useNavigate();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    const isMd = useMediaQuery(theme.breakpoints.down('md'));
    const isLg = useMediaQuery(theme.breakpoints.down('lg'));
    const isXl = useMediaQuery(theme.breakpoints.down('xl'));


    const handleClick = () => {
        navigate('/host-add-offer');
    }

    return (
        <Box
            minHeight={isXs ? 100 : isSm ? 950 : isMd ? 850 : isLg ? 650 : isXl ? 650 : 900}
            height={'auto'}
            position={'relative'}
            sx={{
                backgroundColor: theme.palette.alternate.main,
                background:
                    'url(/media/blur.jpg) no-repeat center',
                backgroundSize: 'cover',
                marginTop: -10,
                paddingTop: 18,
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    width: 1,
                    height: 1,
                    background: alpha(theme.palette.primary.dark, 0.3),
                    zIndex: 1,
                }}
            />
            <Container position={'relative'} zIndex={2}>
                <Box>
                    <Box marginBottom={4} data-aos="fade-up">
                        <Typography
                            variant="h3"
                            gutterBottom
                            sx={{
                                fontWeight: 900,
                                color: 'common.white',
                            }}
                        >
                            {t('hero.title')}
                        </Typography>
                        <Typography
                            variant="h6"
                            component="p"
                            color="text.primary"
                            sx={{
                                fontWeight: 400,
                                color: 'common.white',
                            }}
                        >
                            {t('hero.subtitle_1')}
                            <br/>
                            {t('hero.subtitle_2')}
                        </Typography>
                    </Box>
                    <Box>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <Box
                                    padding={{xs: 3, sm: 6}}
                                    width={1}
                                    component={Card}
                                    boxShadow={4}
                                    data-aos="fade-up"
                                >
                                    <Box
                                        display={'flex'}
                                        justifyContent={'space-between'}
                                        alignItems={{xs: 'flex-start', sm: 'center'}}
                                        flexDirection={{xs: 'column', sm: 'row'}}
                                    >
                                        <Box>
                                            <Typography fontWeight={700} variant={'h6'} gutterBottom>
                                                {t('common.needAParking')}
                                            </Typography>
                                            <Typography>{t('common.searchPromo')}</Typography>
                                        </Box>
                                        <Box display="flex" marginTop={{xs: 2, md: 0}}>
                                            <Button
                                                onClick={() => scrollToTarget('main-page-search-form')}
                                                variant="contained"
                                                color="primary"
                                                size="large">
                                                {t('common.search')}
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box
                                    padding={{xs: 3, sm: 6}}
                                    width={1}
                                    component={Card}
                                    boxShadow={4}
                                    data-aos="fade-up"
                                >
                                    <Box
                                        display={'flex'}
                                        justifyContent={'space-between'}
                                        alignItems={{xs: 'flex-start', sm: 'center'}}
                                        flexDirection={{xs: 'column', sm: 'row'}}
                                    >
                                        <Box>
                                            <Typography fontWeight={700} variant={'h6'} gutterBottom>
                                                {t('common.shareAParking')}
                                            </Typography>
                                            <Typography>{t('common.sharePromo')}</Typography>
                                        </Box>
                                        <Box display="flex" marginTop={{xs: 2, md: 0}}>
                                            <Button variant="contained" size="large" color={'secondary'}
                                                    onClick={handleClick}
                                            >
                                                {t('common.share')}
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default Hero;

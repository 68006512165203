import React, {ReactNode, useEffect} from 'react';
import Main from "../../../layouts/Main";
import {Container} from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import OfferDetails_v2 from "../components/OfferDetails_v2";


const HostAddOffer = (): ReactNode => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Main colorInvert={true}>
            <Box bgcolor={'alternate.main'} paddingTop={1}>
                <Container>
                    <Grid container spacing={{xs: 2, md: 4}}>
                        <Grid item xs={12} md={12} marginTop={2} marginBottom={2}>
                            <OfferDetails_v2/>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Main>
    );
};

export default HostAddOffer;

import React, {ReactNode, useContext, useEffect} from 'react';
import Main from "../../../../layouts/Main";
import {Box} from "@mui/system";
import Container from "../../../../components/Container";
import Search from "../Search";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import NoFiltrationResult from "../FeaturedProperties/NoFiltrationResult";
import {PropertiesContext} from "../../MainView";
import {AppContext, AppContextType} from "../../../../context/AppContextProvider";
import {useStore} from "../../../../store/store";
import parkingPropertyService from "../../../../api/ParkingPropertyApi";
import FeaturedPropertiesPaginated from "../FeaturedProperties/FeaturedPropertiesPaginated";

const ExtendedSearchPage = (): ReactNode => {
    const {t} = useTranslation();
    const {filtrationResult, filtrationFormData, updateContext}: AppContextType = useContext(AppContext);
    const errors = useStore(state => state.errors);

    const content = filtrationResult?.offers?.content ?? [];


    useEffect(() => {
        window.scrollTo(0, 0);

        if (Object.keys(filtrationResult).length === 0) {
            const fetchData = async () => {
                const formDataCopy = Object.assign({}, filtrationFormData);
                formDataCopy.placeId = "";

                try {
                    let allOffers = await parkingPropertyService.getFilteredPropositions(formDataCopy, 0);

                    updateContext({
                        filtrationResult: allOffers.data
                    });
                } catch (error) {
                    console.error("Error fetching data", error);
                }
            };

            fetchData();
        }
    }, []);

    return (
        <Main colorInvert={true}>
            <Box paddingTop={1} bgcolor={'alternate.main'}>
                <Box>
                    <Typography
                        variant="h4"
                        align={'center'}
                        data-aos={'fade-up'}
                        gutterBottom
                        paddingTop={2}
                        sx={{
                            fontWeight: 700,
                        }}
                    >
                        {t('common.extendedSearchPageTitle')}
                    </Typography>
                    <Typography
                        variant="h6"
                        align={'center'}
                        color={'text.secondary'}
                        data-aos={'fade-up'}
                    >
                        {t('common.extendedSearchPageSubTitle')}
                    </Typography>
                </Box>
                <Box>
                    <Container>
                        <Search/>
                    </Container>
                </Box>
                {errors.length === 0 && (
                    <Box bgcolor={'alternate.main'}>
                        {content.length > 0 ? (
                            <Container>
                                <PropertiesContext.Provider value={{isOpen: filtrationResult}}>
                                    <FeaturedPropertiesPaginated/>
                                </PropertiesContext.Provider>
                            </Container>
                        ) : (
                            <Box
                                maxWidth={{sm: 720, md: 1236}}
                                width={1}
                                margin={'0 auto'}
                                paddingX={2}
                                paddingBottom={{xs: 4, sm: 6, md: 8}}
                            >
                                <PropertiesContext.Provider value={{isOpen: filtrationResult}}>
                                    <NoFiltrationResult/>
                                </PropertiesContext.Provider>
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
        </Main>
    );
};

export default ExtendedSearchPage;
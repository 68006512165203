import React from 'react';
import {useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {Feedback} from '../../types/api';
import {useFormik} from 'formik';
import feedbackApi from "../../api/FeedbackApi";

interface FeedbackFormContentProps {
    onClose(): void,

    setFeedbackSent(isFeedbackSent: boolean): void,
}

const validationSchema = yup.object({
    message: yup
        .string()
        .trim()
        .required('yupValidation.requiredFieldGeneral'),
    email: yup
        .string()
        .trim()
        .email('yupValidation.reqEmail')
});

const FeedbackFormContent: React.FunctionComponent<FeedbackFormContentProps> = ({onClose, setFeedbackSent}) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const initialValues: Feedback = {
        firstName: "",
        email: "",
        rating: 3,
        message: ""
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting, setStatus }) => {
            try {
                await feedbackApi.sendFeedback(values);
                setStatus({ success: true });
                setFeedbackSent(true)
            } catch (error) {
                console.error('Error sending feedback:', error);
                setStatus({ success: false });
            } finally {
                setSubmitting(false);
            }
        },
    });

    const handleRatingChange = (rating: number) => {
        formik.setFieldValue('rating', rating);
    };

    return (
        <Box paddingY={2} paddingX={4} height="100%">
            <Box paddingY={2} display={'flex'} justifyContent={'space-between'}>
                <Typography variant={'h5'} fontWeight={700}>
                    {t('feedback.title')}
                </Typography>
                <Box
                    component={'svg'}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    width={24}
                    height={24}
                    onClick={onClose}
                    sx={{cursor: 'pointer'}}
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                    />
                </Box>
            </Box>
            <Box paddingY={2}>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label={t('share.name')}
                                variant="outlined"
                                name="firstName"
                                fullWidth
                                value={formik.values.firstName}
                                onChange={formik.handleChange}
                                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                helperText={formik.touched.firstName && t(formik.errors.firstName || '')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={t('common.yourEmailAddress')}
                                variant="outlined"
                                name="email"
                                fullWidth
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && t(formik.errors.email || '')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={'subtitle2'} sx={{marginBottom: 1}}>
                                {t('common.rating')}
                            </Typography>
                            <Box display={'flex'} alignItems={'center'}>
                                {[1, 2, 3, 4, 5].map((r) => (
                                    <Box
                                        key={r}
                                        component={'svg'}
                                        color={
                                            r <= formik.values.rating
                                                ? theme.palette.primary.main
                                                : theme.palette.divider
                                        }
                                        width={28}
                                        height={28}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        sx={{cursor: 'pointer'}}
                                        onClick={() => handleRatingChange(r)}
                                    >
                                        <path
                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                        />
                                    </Box>
                                ))}
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={'subtitle2'} sx={{marginBottom: 1}}>
                                {t('feedback.writeYourFeedback')}
                            </Typography>
                            <TextField
                                label={t('feedback.feedback')}
                                variant="outlined"
                                name="message"
                                fullWidth
                                multiline
                                rows={5}
                                value={formik.values.message}
                                onChange={formik.handleChange}
                                error={formik.touched.message && Boolean(formik.errors.message)}
                                helperText={formik.touched.message && t(formik.errors.message || '')}
                            />
                        </Grid>
                        <Grid item container xs={12}>
                            <Button size={'large'} variant={'contained'} type={'submit'}>
                                {t('feedback.sent')}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Box>
    );
};

export default FeedbackFormContent;

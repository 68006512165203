import React, { ChangeEvent, DragEvent } from 'react';
import { Box, Button, Card, CardMedia, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Close as CloseIcon } from '@mui/icons-material';

interface FileUploadWithDragAndDropProps {
    formik: any;
}

const FileUploadWithDragAndDrop: React.FC<FileUploadWithDragAndDropProps> = ({ formik }) => {
    const { t } = useTranslation();

    const handleDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const files = e.dataTransfer.files;
        handleFileUpload(files);
    };

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const files = e.target.files;
            handleFileUpload(files);
        }
    };

    const handleFileUpload = (files: FileList) => {
        const urls: string[] = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const url = URL.createObjectURL(file);
            urls.push(url);
        }
        formik.setFieldValue('photos', Array.from(files));
        formik.setFieldValue('imageURLs', urls);
    };

    const handleRemoveImage = (index: number) => {
        const updatedURLs = formik.values.imageURLs.filter((_: any, i: number) => i !== index);
        formik.setFieldValue('imageURLs', updatedURLs);

        const updatedPhotos = formik.values.photos.filter((_: any, i: number) => i !== index);
        formik.setFieldValue('photos', updatedPhotos);
    };

    const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        e.currentTarget.style.background = '#F5F5F5';
    };

    const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        e.currentTarget.style.background = '#FFFFFF';
    };

    return (
        <Box display={'flex'} flexDirection={'column'} width={'100%'}>
            <Box
                sx={{
                    width: '100%',
                    border: formik.touched.photos && formik.errors.photos ? '1px dashed #d32f2f' : '1px dashed #CCCCCC',
                    borderRadius: '5px',
                    padding: '30px',
                    textAlign: 'center',
                    cursor: 'pointer',
                    background: '#FFFFFF',
                }}
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleDrop}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
            >
                <input type="file" id="fileInput" accept="image/*" onChange={handleFileChange} multiple style={{ display: 'none' }} />
                <label htmlFor="fileInput" style={{ cursor: 'pointer' }}>
                    <Typography>{t('share.dragAndDrop')}</Typography>
                    <Typography>{t('share.or')}</Typography>
                    <Button variant="contained" component="span">
                        {t('share.selectFiles')}
                    </Button>
                </label>
                {formik.values.imageURLs && formik.values.imageURLs.length > 0 && (
                    <Grid container spacing={2} mt={2}>
                        {(formik.values.imageURLs as string[]).map((url, index) => (
                            <Grid item key={index} sx={{ position: 'relative' }}>
                                <Card sx={{ position: 'relative' }}>
                                    <CardMedia component="img" image={url} alt={`Uploaded Image ${index + 1}`} sx={{ width: '100px', height: '100px' }} />
                                    <IconButton
                                        size="small"
                                        onClick={() => handleRemoveImage(index)}
                                        sx={{
                                            position: 'absolute',
                                            top: 4,
                                            right: 4,
                                            bgcolor: 'rgba(255,255,255,0.8)',
                                            '&:hover': { bgcolor: 'rgba(255,255,255,1)' },
                                        }}
                                    >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Box>
            {formik.touched.photos && formik.errors.photos && (
                <Typography marginLeft={2} marginTop={1} color="error" variant="caption">
                    {formik.touched.photos && t(formik.errors.photos || '')}
                </Typography>
            )}
        </Box>
    );
};

export default FileUploadWithDragAndDrop;

import CommonApiService from "./CommonApiService";
import {ApiResponse} from "../types/api";

class HostApi extends CommonApiService {

    createOffer(formData: FormData): Promise<ApiResponse<any[]>> {
        return this.postBodyAndFile<any[]>(`${this.baseUrl}/api/host/register`, formData);
    }
}

const hostService = new HostApi();
export default hostService;
import React, {createContext, useState} from 'react';
import {FiltrationBody} from "../types/api";
import dayjs from "dayjs";

export interface AppContextType {
    filtrationFormData: FiltrationBody,
    filtrationResult: any, //TODO: I should migrate it proper data type FiltrationResult
    updateContext: (newContextState: Partial<AppContextType>) => void,
    feedbackDialogIsOpen: boolean
}

interface Props {
    children: React.ReactNode;
}

//TODO: this need to be removed at all and formink need to be used instead on place
const defaultFiltrationFormData: FiltrationBody = {
    location: "",
    placeId: "",
    startDateTime: dayjs(),
    endDateTime: dayjs().add(1, 'hour'),
    parkingTypes: [],
    spotLength: null,
    spotWidth: null,
    spotHeight: null,
    lpgAllowed: false,
    chargerPresent: false,
    guarded: false,
    accessible: false,
};

const defaultContext: AppContextType = {
    feedbackDialogIsOpen: false,
    filtrationFormData: defaultFiltrationFormData,
    filtrationResult: {},
    updateContext: () => {
    }
};

export const AppContext = createContext(defaultContext);

const AppContextProvider: React.FC<Props> = ({children}) => {
    const [context, setContext] = useState<AppContextType>(defaultContext);

    const updateContext = (newContext: Partial<AppContextType>) => {
        setContext(prevContext => ({...prevContext, ...newContext}));
    };

    return (
        <AppContext.Provider value={{...context, updateContext}}>
            {children}
        </AppContext.Provider>
    );
};

export default AppContextProvider;

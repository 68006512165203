// import * as React from 'react';
// import OutlinedInput from '@mui/material/OutlinedInput';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select, {SelectChangeEvent} from '@mui/material/Select';
// import {Checkbox, FormControlLabel, FormHelperText, Radio, RadioGroup} from "@mui/material";
// import ListItemText from "@mui/material/ListItemText";
// import {FiltrationBody} from "../types/api";
// import {useTranslation} from "react-i18next";
// import {Box} from "@mui/system";
//
// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//     PaperProps: {
//         style: {
//             maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//             width: 250,
//         },
//     },
// };
//
// const names = [
//     'UNDERGROUND',
//     'GARAGE',
//     'OPENAIR'
// ];
//
// interface Props {
//     formik: any
// }
//
// const SingleSelector: React.FunctionComponent<Props> = ({ formik }) => {
//     const {t} = useTranslation()
//
//     const handleSelectChange = (event: SelectChangeEvent) => {
//         formik.setFieldValue('parkingType', event.target.value);
//     };
//
//     return (
//         <Box
//             width={1}
//             marginRight={{ xs: 0, md: 2 }}
//             marginBottom={{ xs: 2, md: 0 }}
//         >
//             <FormControl fullWidth>
//                 <InputLabel id="demo-single-select-label">{t('common.parkingType')}</InputLabel>
//                 <Select
//                     name={'parkingType'}
//                     labelId="demo-single-select-label"
//                     id="demo-single-select"
//                     value={t(`common.${formik.values.parkingType.toLowerCase()}`)}
//                     onChange={handleSelectChange}
//                     input={<OutlinedInput label="parking_type" />}
//                     renderValue={(selected) => selected}
//                     MenuProps={MenuProps}
//                     error={formik.touched.parkingType && Boolean(formik.errors.parkingType)}
//
//                 >
//                     <RadioGroup value={formik.values.parkingType} onChange={handleSelectChange}>
//                         {names.map((name) => (
//                             <MenuItem key={name} value={name}>
//                                 <FormControlLabel
//                                     value={name}
//                                     control={<Radio checked={formik.values.parkingType === name} />}
//                                     label={t(`common.${name.toLowerCase()}`)}
//                                 />
//                             </MenuItem>
//                         ))}
//                     </RadioGroup>
//                 </Select>
//                 {formik.touched.parkingType && formik.errors.parkingType && (
//                     <FormHelperText error>{t(formik.errors.parkingType)}</FormHelperText>
//                 )}
//             </FormControl>
//         </Box>
//     );
// }
//
// export default SingleSelector;

import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FormControlLabel, FormHelperText, Radio, RadioGroup } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'UNDERGROUND',
    'GARAGE',
    'OPENAIR'
];

interface Props {
    formik: any
}

const SingleSelector: React.FunctionComponent<Props> = ({ formik }) => {
    const { t } = useTranslation();

    const handleSelectChange = (event: SelectChangeEvent) => {
        formik.setFieldValue('parkingType', event.target.value);
    };

    return (
        <Box
            width={1}
            marginRight={{ xs: 0, md: 2 }}
            marginBottom={{ xs: 2, md: 0 }}
        >
            <FormControl fullWidth>
                <InputLabel id="demo-single-select-label">{t('common.parkingType')}</InputLabel>
                <Select
                    name={'parkingType'}
                    labelId="demo-single-select-label"
                    id="demo-single-select"
                    value={formik.values.parkingType}
                    onChange={handleSelectChange}
                    input={<OutlinedInput label="parking_type" />}
                    renderValue={(selected) => t(`common.${selected.toLowerCase()}`)}
                    MenuProps={MenuProps}
                    error={formik.touched.parkingType && Boolean(formik.errors.parkingType)}
                >
                    {names.map((name) => (
                        <MenuItem key={name} value={name}>
                            <FormControlLabel
                                value={name}
                                control={<Radio checked={formik.values.parkingType === name} />}
                                label={t(`common.${name.toLowerCase()}`)}
                            />
                        </MenuItem>
                    ))}
                </Select>
                {formik.touched.parkingType && formik.errors.parkingType && (
                    <FormHelperText error>{t(formik.errors.parkingType)}</FormHelperText>
                )}
            </FormControl>
        </Box>
    );
}

export default SingleSelector;

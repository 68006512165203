import React, {ReactNode, useContext} from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {Offer} from "../../../../types/api";
import AdditionalFeatures from "../AdditionalFeatures/AdditionalFeatures";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import {ResourceLoaderContext, ResourceLoaderContextType} from "../../../../components/ResourceLoaderContext";
import {Skeleton} from "@mui/material";
import Grid from "@mui/material/Grid";
import {isPriceWithPromo} from "../../../../utils/CostCalc";

interface DetailsProps {
    currentOfferDetails: Offer,
    handleClick: () => void
}

const Details = ({currentOfferDetails, handleClick}: DetailsProps): ReactNode => {
    const {
        availableForBooking,
        offerAddress,
        description,
        price,
        requestedStartDateTime,
        requestedEndDateTime,
        parkingType,
        spotLength,
        spotWidth,
        spotHeight,
        costDetails
    } = currentOfferDetails || {};

    const isNotPreview = !!requestedStartDateTime && !!requestedEndDateTime;
    const {fee, cost, feeAfterPromo, totalAfterPromo} = costDetails || {};
    const {isLoaded}: ResourceLoaderContextType = useContext(ResourceLoaderContext);
    const {t} = useTranslation();

    const DiscountPriceTypography: ReactNode = isNotPreview && isPriceWithPromo(costDetails) ?
        (<Box display="flex" flexDirection="column">
            <Typography variant="subtitle2" color="text.secondary">
                {`${t('price.fee')} `}
                <span style={{
                    fontWeight: 'bold',
                    textDecoration: 'line-through',
                    color: 'red',
                }}>
                    {`${fee} zł`}
                </span>
                {` ${feeAfterPromo} zł *`}
            </Typography>
            <Typography variant="caption" color="text.secondary">
                {t('price.promoText')}
            </Typography>
        </Box>) :
        (
            <Typography variant={'subtitle2'} color="text.secondary">
                {`${t('price.fee')} ${fee} zł`}
            </Typography>
        )


    const handleEmailClick = () => {
        window.location.href = 'mailto:contact@parkio.co';
    };

    return (
        <Box>
            <Box marginBottom={1} display={'flex'} justifyContent={'space-between'}>
                <Typography fontWeight={600} noWrap>
                    {t('common.details')}
                </Typography>
                <Typography fontWeight={700} noWrap>
                    {isLoaded ?
                        `${price}zł / godzinę` :
                        <Skeleton animation={'wave'} variant="rounded" width="100%"/>
                    }
                </Typography>
            </Box>
            {isNotPreview && <Box display={'flex'} alignItems={'center'}>
                <Box
                    component={'svg'}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#000000"
                    viewBox="0 0 24 24"
                    width={16}
                    height={16}
                    marginRight={1}
                >
                    <path d="M0 0h24v24H0V0z" fill="none"/>
                    <path
                        d="M7 11h2v2H7v-2zm14-5v14c0 1.1-.9 2-2 2H5c-1.11 0-2-.9-2-2l.01-14c0-1.1.88-2 1.99-2h1V2h2v2h8V2h2v2h1c1.1 0 2 .9 2 2zM5 8h14V6H5v2zm14 12V10H5v10h14zm-4-7h2v-2h-2v2zm-4 0h2v-2h-2v2z"
                    />
                </Box>
                <Typography variant={'subtitle2'} color="text.secondary" sx={{width: '100%'}}>
                    {isLoaded ?
                        `${dayjs(requestedStartDateTime).format('DD-MM-YYYY HH:mm')} - 
                        ${dayjs(requestedEndDateTime).format('DD-MM-YYYY HH:mm')}` :
                        <Skeleton animation={'wave'} variant="rounded" width="60%"/>
                    }
                </Typography>
            </Box>}
            <Box display={'flex'} alignItems={'center'} marginY={2}>
                <Box
                    component={'svg'}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    width={16}
                    height={16}
                    marginRight={1}
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                    />
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                </Box>
                <Typography variant={'subtitle2'} color="text.secondary" sx={{width: '100%'}}>
                    {isLoaded ?
                        offerAddress :
                        <Skeleton animation={'wave'} variant="rounded" width="60%"/>
                    }
                </Typography>
            </Box>
            <Box display={'flex'} alignItems={'center'} marginY={2}>
                <Box
                    component={'svg'}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    width={16}
                    height={16}
                    marginRight={1}
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                    />
                </Box>
                <Typography variant={'subtitle2'} color="text.secondary" sx={{width: '100%'}}>
                    {isLoaded ?
                        t(`common.${parkingType.toLowerCase()}`) :
                        <Skeleton animation={'wave'} variant="rounded" width="60%"/>
                    }
                </Typography>
            </Box>
            <Box display={'flex'} alignItems={'center'}>
                <Box
                    component={'svg'}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    width={16}
                    height={16}
                    marginRight={1}
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                    />
                </Box>
                <Typography variant={'subtitle2'} color="text.secondary" sx={{width: '100%'}}>
                    {isLoaded ?
                        `${spotLength} x ${spotWidth} x ${spotHeight}` :
                        <Skeleton animation={'wave'} variant="rounded" width="60%"/>
                    }
                </Typography>
            </Box>
            <Box marginTop={2}>
                <Typography
                    variant={'subtitle2'}
                    color={'text.secondary'}
                >
                    {isLoaded ?
                        description :
                        <Stack marginTop={3}>
                            <Skeleton animation={'wave'} variant="rounded" width="100%" height={10}
                                      style={{marginBottom: 6}}/>
                            <Skeleton animation={'wave'} variant="rounded" width="90%" height={10}
                                      style={{marginBottom: 6}}/>
                            <Skeleton animation={'wave'} variant="rounded" width="95%" height={10}
                                      style={{marginBottom: 6}}/>
                            <Skeleton animation={'wave'} variant="rounded" width="70%" height={10}
                                      style={{marginBottom: 6}}/>
                        </Stack>
                    }
                </Typography>
            </Box>
            <Box marginTop={2}>
                {isLoaded ?
                    <AdditionalFeatures currentOfferDetails={currentOfferDetails}/> :
                    <Grid container spacing={2} sx={{flexDirection: {xs: 'column', sm: 'row'}}}>
                        <Grid item xs={12} sm={6}>
                            <Skeleton animation={'wave'} variant="rounded" width="80%"/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Skeleton animation={'wave'} variant="rounded" width="80%"/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Skeleton animation={'wave'} variant="rounded" width="80%"/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Skeleton animation={'wave'} variant="rounded" width="80%"/>
                        </Grid>
                    </Grid>
                }
            </Box>
            <Divider sx={{marginTop: 2}}/>
            {isNotPreview &&
                <>
                    {
                        availableForBooking &&
                        <>
                            <Box marginBottom={1} marginTop={2} display={'flex'} justifyContent={'space-between'}>
                                <Typography fontWeight={600} noWrap>
                                    {t('price.paymentSummary')}
                                </Typography>
                            </Box>
                            <Box display={'flex'} alignItems={'center'} marginY={2}>
                                <Box
                                    component={'svg'}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    width={16}
                                    height={16}
                                    marginRight={1}
                                    fill="#00000"
                                >
                                    <path
                                        fill="#00000"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={0.1}
                                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm.31-8.86c-1.77-.45-2.34-.94-2.34-1.67 0-.84.79-1.43 2.1-1.43 1.38 0 1.9.66 1.94 1.64h1.71c-.05-1.34-.87-2.57-2.49-2.97V5H10.9v1.69c-1.51.32-2.72 1.3-2.72 2.81 0 1.79 1.49 2.69 3.66 3.21 1.95.46 2.34 1.15 2.34 1.87 0 .53-.39 1.39-2.1 1.39-1.6 0-2.23-.72-2.32-1.64H8.04c.1 1.7 1.36 2.66 2.86 2.97V19h2.34v-1.67c1.52-.29 2.72-1.16 2.73-2.77-.01-2.2-1.9-2.96-3.66-3.42z"/>

                                </Box>
                                <Typography variant={'subtitle2'} color="text.secondary" sx={{width: '100%'}}>
                                    {isLoaded ?
                                        `${t('price.totalWithoutFee')} ${cost} zł` :
                                        <Skeleton animation={'wave'} variant="rounded" width="60%"/>
                                    }
                                </Typography>
                            </Box>
                            <Box display={'flex'} alignItems={'center'} marginY={2}>
                                <Box
                                    component={'svg'}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    width={16}
                                    height={16}
                                    marginRight={1}
                                    fill="#00000"
                                >
                                    <path
                                        fill="#00000"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={0.1}
                                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm.31-8.86c-1.77-.45-2.34-.94-2.34-1.67 0-.84.79-1.43 2.1-1.43 1.38 0 1.9.66 1.94 1.64h1.71c-.05-1.34-.87-2.57-2.49-2.97V5H10.9v1.69c-1.51.32-2.72 1.3-2.72 2.81 0 1.79 1.49 2.69 3.66 3.21 1.95.46 2.34 1.15 2.34 1.87 0 .53-.39 1.39-2.1 1.39-1.6 0-2.23-.72-2.32-1.64H8.04c.1 1.7 1.36 2.66 2.86 2.97V19h2.34v-1.67c1.52-.29 2.72-1.16 2.73-2.77-.01-2.2-1.9-2.96-3.66-3.42z"/>

                                </Box>
                                <Typography variant={'subtitle2'} color="text.secondary" sx={{width: '100%'}}>
                                    {isLoaded ?
                                        DiscountPriceTypography :
                                        <Skeleton animation={'wave'} variant="rounded" width="60%"/>
                                    }
                                </Typography>
                            </Box>
                            <Box display={'flex'} alignItems={'center'} marginY={2}>
                                <Box
                                    component={'svg'}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    width={16}
                                    height={16}
                                    marginRight={1}
                                    fill="#00000"
                                >
                                    <path
                                        fill="#00000"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={0.1}
                                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm.31-8.86c-1.77-.45-2.34-.94-2.34-1.67 0-.84.79-1.43 2.1-1.43 1.38 0 1.9.66 1.94 1.64h1.71c-.05-1.34-.87-2.57-2.49-2.97V5H10.9v1.69c-1.51.32-2.72 1.3-2.72 2.81 0 1.79 1.49 2.69 3.66 3.21 1.95.46 2.34 1.15 2.34 1.87 0 .53-.39 1.39-2.1 1.39-1.6 0-2.23-.72-2.32-1.64H8.04c.1 1.7 1.36 2.66 2.86 2.97V19h2.34v-1.67c1.52-.29 2.72-1.16 2.73-2.77-.01-2.2-1.9-2.96-3.66-3.42z"/>

                                </Box>
                                <Typography variant={'subtitle2'} color="text.secondary" fontWeight={'bold'}
                                            sx={{width: '100%'}}>
                                    {isLoaded ?
                                        `${t('price.totalWithFee')} ${totalAfterPromo} zł` :
                                        <Skeleton animation={'wave'} variant="rounded" width="60%"/>
                                    }
                                </Typography>
                            </Box>
                        </>
                    }
                    <Box marginTop={4}>
                        <Button
                            href={'#'}
                            variant={'contained'}
                            color={'primary'}
                            size={'large'}
                            fullWidth
                            onClick={handleClick}
                            disabled={!availableForBooking}
                        >
                            {availableForBooking ? t('common.bookParking') : t('common.reserved')}
                        </Button>
                    </Box>
                    <Box marginTop={4}>
                        <Typography>{t('share.needAsupport')}</Typography>
                        <Stack direction={'row'} spacing={2} marginTop={0.5}>
                            <Button
                                sx={{
                                    color: 'text.secondary',
                                }}
                                startIcon={
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={20}
                                        height={20}
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"/>
                                        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"/>
                                    </svg>
                                }
                                onClick={handleEmailClick}
                            >
                                {t('share.emailUs')}
                            </Button>
                        </Stack>
                    </Box>
                </>
            }
        </Box>
    );
};

export default Details;

import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Media } from '../../../../types/api';
import { SvgIcon } from '@mui/material';
import Typography from '@mui/material/Typography';
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";

interface ImageProps {
    media: Media[];
    availableForBooking: boolean;
}

const baseUrl = process.env.REACT_APP_BASE_URL;

const Image = ({ media, availableForBooking }: ImageProps): JSX.Element => {
    const {t} = useTranslation();
    const theme = useTheme();

    return (
        <Grid container spacing={2} sx={{ height: '100%' }}>
            {media.map((item, i) => (
                <Grid key={item.id} item xs={i === 0 ? 12 : 6}>
                    <Box
                        sx={{
                            position: 'relative',
                            display: 'flex',
                            height: '100%',
                            '& img': {
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                borderRadius: 2,
                            },
                        }}
                    >
                        <img
                            src={baseUrl + item.url}
                            alt={'Parking spot'}
                            loading={'lazy'}
                        />
                        {i === 0 && !availableForBooking && (
                            <Box
                                display={'flex'}
                                justifyContent={'space-between'}
                                position={'absolute'}
                                bottom={0}
                                left={0}
                                padding={2}
                                bgcolor={theme.palette.secondary.main}
                                boxShadow={1}
                                borderRadius={2}
                                height={4}
                                sx={{ margin: 1 }}
                            >
                                <Box display="flex" alignItems="center">
                                    <SvgIcon >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"
                                        />
                                    </SvgIcon>
                                    <Typography sx={{ fontWeight: 600, marginLeft: 1 }}>
                                        {t("common.reserved")}
                                    </Typography>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Grid>
            ))}
        </Grid>
    );
};

export default Image;

import React from "react";
import {Checkbox, FormControlLabel, FormGroup} from "@mui/material";
import Box from "@mui/material/Box";
import {FiltrationBody} from "../types/api";
import {useTranslation} from "react-i18next";

interface Props {
    handleChange(event: React.ChangeEvent<HTMLInputElement>): void,
    lpgAllowed: boolean,
    chargerPresent: boolean,
    guarded: boolean,
    accessible: boolean
}

const CheckBoxGroup: React.FunctionComponent<Props> = ({
                                                           handleChange,
                                                           lpgAllowed,
                                                           chargerPresent,
                                                           guarded,
                                                           accessible
                                                       }) => {
    const {t} = useTranslation()

    return (
        <FormGroup>
            <Box
                display={'flex'}
                flexDirection={{xs: 'column', sm: 'row'}}
            >
                <Box>
                    <FormControlLabel control={
                        <Checkbox
                            disabled
                            name={'lpgAllowed'}
                            checked={lpgAllowed}
                            onChange={handleChange}
                            inputProps={{'aria-label': 'controlled'}}
                        />
                    } label={t('common.gasPoweredSearch')}/>
                </Box>
                <Box>
                    <FormControlLabel control={
                        <Checkbox
                            disabled
                            name={'chargerPresent'}
                            checked={chargerPresent}
                            onChange={handleChange}
                            inputProps={{'aria-label': 'controlled'}}
                        />
                    } label={t('common.electricCarCharger')}/>
                </Box>
                <Box>
                    <FormControlLabel control={
                        <Checkbox
                            disabled
                            name={'guarded'}
                            checked={guarded}
                            onChange={handleChange}
                            inputProps={{'aria-label': 'controlled'}}
                        />
                    } label={t('common.guardedParking')}/>
                </Box>
                <Box>
                    <FormControlLabel control={
                        <Checkbox
                            disabled
                            name={'accessible'}
                            checked={accessible}
                            onChange={handleChange}
                            inputProps={{'aria-label': 'controlled'}}
                        />
                    } label={t('common.accessibleParking')}/>
                </Box>
            </Box>
        </FormGroup>
    )
}

export default CheckBoxGroup;


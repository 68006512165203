class CommonServerError extends Error {
    errorResponse: any;

    constructor(errorResponse: any) {
        super("Server error received");
        this.errorResponse = errorResponse;
        Object.setPrototypeOf(this, CommonServerError.prototype);
    }
}

export default CommonServerError;

/* eslint-disable react/no-unescaped-entities */
import React, {ReactNode} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material/styles';
import {Position} from "../../types/api";
import {useTranslation} from "react-i18next";

interface MiniMapProps {
    position: Position
}

const MiniMap = ({position}: MiniMapProps): ReactNode => {
    const {t} = useTranslation();
    const {lat, lng} = position;
    const googleMapUrl = `https://maps.google.com/maps?width=100%&height=100%&hl=pl&q=${lat},${lng}&ie=UTF8&t=&z=14&iwloc=B&output=embed`;

    const theme = useTheme();
    return (
        <Box>
            <Box>
                <Box>
                    <Typography variant="h6" color={'text.secondary'}>
                        {t('common.location')}
                    </Typography>
                </Box>
            </Box>
            <Box marginY={3}>
                <iframe
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    title="map"
                    src={googleMapUrl}
                    style={{
                        minHeight: 300,
                        borderRadius: 8,
                        filter:
                            theme.palette.mode === 'dark'
                                ? 'grayscale(0.5) opacity(0.7)'
                                : 'none',
                    }}
                />
            </Box>
        </Box>
    );
};

export default MiniMap;

import dayjs from "dayjs";
import {PlaceType} from "./componentTypes";

export enum ParkingType {
    OPEN = "OPENAIR",
    UNDERGROUND = "UNDERGROUND",
    GARAGE = "GARAGE"
}

interface AutocompleteResponse {
    predictions: PlaceType[];
}

interface ApiResponse<T> {
    success: boolean;
    data?: T;
    error?: string;
}

interface Media {
    id: string,
    url: string,
    description: string,
    mediaType: string

}

interface FiltrationBody {
    location: string,
    placeId: string | null,
    startDateTime: dayjs.Dayjs,
    endDateTime: dayjs.Dayjs,
    parkingTypes: string[],
    spotLength: number | null,
    spotWidth: number | null,
    spotHeight: number | null,
    lpgAllowed: boolean,
    chargerPresent: boolean,
    guarded: boolean,
    accessible: boolean,
}

interface Offer {
    availableForBooking: boolean,
    id: string,
    url: string,
    position: Position
    offerAddress: string,
    media: Media[],
    title: string,
    description: string,
    costDetails: CostDetails,
    price: number,
    location: PlaceType | null,
    requestedStartDateTime: dayjs.Dayjs,
    requestedEndDateTime: dayjs.Dayjs,
    parkingType: ParkingType,
    spotLength: number | null,
    spotWidth: number | null,
    spotHeight: number | null,
    lpgAllowed: boolean,
    chargerPresent: boolean,
    guarded: boolean,
    accessible: boolean,
}

interface FiltrationResult {
    selectedPlace: Position,
    offers: Offer[],
}

interface Position {
    lat: number,
    lng: number
}

interface ReservationDetails {
    firstName: string | null
    lastName: string | null
    email: string | null
    phone: string | null
    offerId: string | null
    startTime: dayjs.Dayjs | null
    endTime: dayjs.Dayjs | null
    message: string | null
}

export enum CheckoutStatus {
    LOCKED = "LOCKED",
    PAYED = "PAYED",
    UNPAID = "UNPAID",
    CONFIRMED = "CONFIRMED",
    FINISHED = "FINISHED",
    CANCELLED = "CANCELLED"
}


interface CheckoutResult {
    status: string,
    startTime: dayjs.Dayjs | null,
    endTime: dayjs.Dayjs | null,
    offerAddress: string
    description: string
}

interface Slot {
    startDateTime: dayjs.Dayjs,
    endDateTime: dayjs.Dayjs,
    price: number,
    slotId: string
}

interface HostOfferDetails {
    location: string,
    firstName: string | null,
    lastName: string | null,
    // bankAccountNumber: string | null
    email: string | null,
    phoneNumber: string | null,
    placeId: string | null,
    parkingType: string | null,
    spotLength: number | null,
    spotWidth: number | null,
    spotHeight: number | null,
    lpgAllowed: boolean,
    chargerPresent: boolean,
    guarded: boolean,
    accessible: boolean,
    schedules: Slot[] | null,
    photos: [],
    description: string
}

interface CostDetails {
    currency: string;
    cost: number;
    fee: number;
    costAfterPromo: number;
    feeAfterPromo: number;
    total: number;
    totalAfterPromo: number;
}


interface Feedback {
    firstName: string | null,
    email: string | null,
    rating: number,
    message: string | null
}

export type {
    ApiResponse,
    FiltrationBody,
    FiltrationResult,
    AutocompleteResponse,
    Position,
    Offer,
    Media,
    ReservationDetails,
    CheckoutResult,
    HostOfferDetails,
    Slot,
    Feedback,
    CostDetails
};
import React, { useContext, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { AppContext, AppContextType } from "../../../../context/AppContextProvider";
import { Offer } from "../../../../types/api";
import MainMap from "../../../../blocks/Map/MainMap";
import { useTranslation } from "react-i18next";
import OfferCard from "./OfferCard";
import { ResourceLoaderContext, ResourceLoaderContextType } from "../../../../components/ResourceLoaderContext";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import parkingPropertyService from "../../../../api/ParkingPropertyApi";
import { scrollToTarget } from "../../../../utils/Scroll";

const FeaturedPropertiesPaginated = (): JSX.Element => {
    const { filtrationFormData, filtrationResult, updateContext }: AppContextType = useContext(AppContext);
    const { isGoogleMapsLoaded, isLoaded }: ResourceLoaderContextType = useContext(ResourceLoaderContext);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const isSearchPage = location.pathname === '/search-parking';
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down('md'));

    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        if (filtrationResult?.offers?.pageable?.pageNumber !== undefined) {
            setCurrentPage(filtrationResult.offers.pageable.pageNumber + 1);
        }
    }, [filtrationResult]);

    useEffect(() => {
        if (isSearchPage) {
            // eto pozor kakojto, no her snim, ja hz kak sdelat po drugomu
            setTimeout(() => {
                scrollToTarget('parking-map');
            }, 0);
        }
    }, [location.pathname, isSearchPage]);

    const handlePageChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
        try {
            let allOffers = await parkingPropertyService.getFilteredPropositions(filtrationFormData, value - 1);
            updateContext({
                filtrationResult: allOffers.data
            });

            scrollToTarget('parking-map');
        } catch (error) {
            console.error("Error fetching data", error);
        }
    };

    const handleClick = () => {
        navigate('/search-parking');
    };

    const content = filtrationResult?.offers?.content ?? [];
    const totalPages = filtrationResult?.offers?.totalPages ?? 0;

    return (
        <Box>
            <Box marginBottom={4}>
                <Typography
                    sx={{
                        textTransform: 'uppercase',
                        fontWeight: 'medium',
                    }}
                    gutterBottom
                    color={'secondary'}
                    align={'center'}
                    id={'parking-map'}
                >
                    {t('common.featuredPatkingSpots')}
                </Typography>
                <Typography
                    variant="h4"
                    align={'center'}
                    data-aos={'fade-up'}
                    gutterBottom
                    sx={{
                        fontWeight: 700,
                    }}
                >
                    {t('common.betterWay')}
                </Typography>
                <Typography
                    variant="h6"
                    align={'center'}
                    color={'text.secondary'}
                    data-aos={'fade-up'}
                >
                    {t('common.exploreParking')}
                </Typography>
            </Box>

            {isGoogleMapsLoaded && <MainMap />}

            <Box marginBottom={4}>
                <Typography
                    variant="h4"
                    align={'center'}
                    data-aos={'fade-up'}
                    gutterBottom
                    sx={{
                        fontWeight: 700,
                    }}
                >
                    {t('price.promoText')}
                </Typography>
            </Box>
            <Grid container spacing={4}>
                {content.map((offer: Offer, i: number) => (
                    <OfferCard offer={offer} key={i} />
                ))}
            </Grid>
            {isSearchPage && (
                <Box
                    display="flex"
                    justifyContent="center"
                    marginTop={{ xs: 4, md: 4 }}
                >
                    <Pagination
                        size={isMd ? 'small' : 'large'}
                        count={totalPages}
                        showFirstButton
                        showLastButton
                        page={currentPage}
                        onChange={handlePageChange}
                    />
                </Box>
            )}
            {!isSearchPage &&
                <Box
                    display="flex"
                    flexDirection={{ xs: 'column', md: 'row' }}
                    marginTop={{ xs: 2, md: 5 }}
                >
                    <Button
                        sx={{ height: 54, whiteSpace: 'nowrap' }}
                        variant="contained"
                        color="primary"
                        size="medium"
                        fullWidth
                        type={'submit'}
                        onClick={handleClick}
                    >
                        {t('common.findAll')}
                    </Button>
                </Box>
            }
        </Box>
    );
};

export default FeaturedPropertiesPaginated;
import React, {ReactNode} from 'react';

import RentalDetailsView from "./RentalDetails";
import MainView from "./Main";
import HostAddOffer from "./Host/HostAdd";
import PaymentResult from "./Pyment/PaymentResult";
import LegalPolicies from "./Policies/LegalPolicies";
import PageNotFound from "./PageNotFound";
import OfferCreatedSuccessfully from "./Host/components/OfferCreatedSuccessfully";
import ExtendedSearchPage from "./Main/components/ParkingSearch/ExtendedSearchPage";
import MaintenancePage from "./MaintenancePage";

const routes = [
    {
        path: '/',
        renderer: (params = {}): ReactNode => <MainView {...params} />,
    }, {
        path: '/rental-details/:id',
        renderer: (params = {}): ReactNode => <RentalDetailsView {...params} />,
    }, {
        path: '/host-add-offer',
        renderer: (params = {}): ReactNode => <HostAddOffer {...params} />,
    }, {
        path: '/payment-progress/:reservationId',
        renderer: (params = {}): ReactNode => <PaymentResult {...params} />,
    }, {
        path: '/legal-policy/:policy',
        renderer: (params = {}): ReactNode => <LegalPolicies {...params} />,
    }, {
        path: '/page-not-found',
        renderer: (params = {}): ReactNode => <PageNotFound {...params} />,
    }, {
        path: '/offer-created-successfully',
        renderer: (params = {}): ReactNode => <OfferCreatedSuccessfully {...params} />,
    }, {
        path: '/search-parking',
        renderer: (params = {}): ReactNode => <ExtendedSearchPage {...params} />,
    }, {
        path: '/page-is-under-maintenance',
        renderer: (params = {}): ReactNode => <MaintenancePage {...params} />,
    }
];

export default routes;

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";

const GuardedParking = <Box display={'flex'} alignItems={'center'}>
    <Box
        component={'svg'}
        xmlns="http://www.w3.org/2000/svg"
        fill="#000000"
        viewBox="0 0 24 24"
        width={16}
        height={16}
        marginRight={1}
    >
        <path d="M0,0h24v24H0V0z" fill="none"/>
        <path d="M12,2L4,5v6.09c0,5.05,3.41,9.76,8,10.91c4.59-1.15,8-5.86,8-10.91V5L12,2z M18,11.09c0,4-2.55,7.7-6,8.83 c-3.45-1.13-6-4.82-6-8.83V6.31l6-2.12l6,2.12V11.09z M8.82,10.59L7.4,12l3.54,3.54l5.66-5.66l-1.41-1.41l-4.24,4.24L8.82,10.59z"/>
    </Box>
    <Typography variant={'subtitle2'} color="text.secondary">
        {'Miejsce strzeżone'}
    </Typography>
</Box>

const AccessibleParking = <Box display={'flex'} alignItems={'center'}>
    <Box
        component={'svg'}
        xmlns="http://www.w3.org/2000/svg"
        fill="#000000"
        viewBox="0 0 24 24"
        width={16}
        height={16}
        marginRight={1}
    >
        <path d="M0 0h24v24H0V0z" fill="none"/>
        <circle cx="12" cy="4" r="2"/>
        <path d="M19 13v-2c-1.54.02-3.09-.75-4.07-1.83l-1.29-1.43c-.17-.19-.38-.34-.61-.45-.01 0-.01-.01-.02-.01H13c-.35-.2-.75-.3-1.19-.26C10.76 7.11 10 8.04 10 9.09V15c0 1.1.9 2 2 2h5v5h2v-5.5c0-1.1-.9-2-2-2h-3v-3.45c1.29 1.07 3.25 1.94 5 1.95zm-9 7c-1.66 0-3-1.34-3-3 0-1.31.84-2.41 2-2.83V12.1c-2.28.46-4 2.48-4 4.9 0 2.76 2.24 5 5 5 2.42 0 4.44-1.72 4.9-4h-2.07c-.41 1.16-1.52 2-2.83 2z"/>
    </Box>
    <Typography variant={'subtitle2'} color="text.secondary">
        {'Miejsce dla niepełnosprawnych'}
    </Typography>
</Box>

const GasPoweredAllowed = <Box display={'flex'} alignItems={'center'}>
    <Box
        component={'svg'}
        xmlns="http://www.w3.org/2000/svg"
        fill="#000000"
        viewBox="0 0 24 24"
        width={16}
        height={16}
        marginRight={1}
    >
        <path d="M0 0h24v24H0V0z" fill="none"/>
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z"/>
    </Box>
    <Typography variant={'subtitle2'} color="text.secondary">
        {'Dozwolone również dla aut z LPG'}
    </Typography>
</Box>

const ElectricCarCharger = <Box display={'flex'} alignItems={'center'}>
    <Box
        component={'svg'}
        xmlns="http://www.w3.org/2000/svg"
        fill="#000000"
        viewBox="0 0 24 24"
        width={16}
        height={16}
        marginRight={1}
    >
        <path d="M21,14c0-0.55-0.45-1-1-1h-2v2h2C20.55,15,21,14.55,21,14z"/>
        <path d="M20,17h-2v2h2c0.55,0,1-0.45,1-1C21,17.45,20.55,17,20,17z"/>
        <path d="M12,14h-2v4h2c0,1.1,0.9,2,2,2h3v-8h-3C12.9,12,12,12.9,12,14z"/>
        <path d="M5,13c0-1.1,0.9-2,2-2h1.5c1.93,0,3.5-1.57,3.5-3.5S10.43,4,8.5,4H5C4.45,4,4,4.45,4,5c0,0.55,0.45,1,1,1h3.5 C9.33,6,10,6.67,10,7.5S9.33,9,8.5,9H7c-2.21,0-4,1.79-4,4c0,2.21,1.79,4,4,4h2v-2H7C5.9,15,5,14.1,5,13z"/>
    </Box>
    <Typography variant={'subtitle2'} color="text.secondary">
        {'Ładowarka samochodów elektrycznych'}
    </Typography>
</Box>

export {
    GuardedParking,
    AccessibleParking,
    GasPoweredAllowed,
    ElectricCarCharger
}
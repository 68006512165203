import React, {ChangeEvent, useState} from 'react';
import {InputAdornment, TextField} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import {useTranslation} from "react-i18next";
import {PlaceType} from "../types/componentTypes";

interface PhoneNumberInputProps {
    formik: any;
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
    formik
                                                           }) => {
    const {t} = useTranslation()

    const phoneNumberFormatter = (phoneNumberToFormat: string) => {
        const chars: string[] = phoneNumberToFormat.split('');
        let resultString = '';
        let counter = 0

        chars.forEach((i) => {
            if(i !== " "){
                if (counter === 3) {
                    resultString = resultString
                        .concat(" ")
                        .concat(i)
                    counter = 1;
                } else {
                    resultString = resultString.concat(i)
                    counter++
                }
            }
        })

        return resultString;
    }

    const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
        // formik.setFieldValue('phoneNumber', event ? phoneNumberFormatter(event.target.value).trim() : '');
        formik.setFieldValue('phoneNumber', event ? event.target.value.trim() : '');
    };


    return (
        <div>
            <TextField
                fullWidth
                id="phoneNumber"
                name={'phoneNumber'}
                label={t('share.phoneNumber')}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <PhoneIcon/>
                        </InputAdornment>
                    ),
                }}
                onChange={handlePhoneNumberChange}
                value={formik.values.phoneNumber}
                error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                helperText={formik.touched.phoneNumber && t(formik.errors.phoneNumber || '')}
            />
        </div>
    );
};

export default PhoneNumberInput;

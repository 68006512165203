export const scrollToTarget = (elementId: string) => {
    const targetElement = document.getElementById(elementId);
    const appBar = document.getElementById('app_bar');

    if (targetElement && appBar) {
        let offsetPosition = targetElement.offsetTop;

        if (appBar.style.position === 'fixed') {
            const appBarHeight = appBar.offsetHeight;
            offsetPosition -= appBarHeight;
        }

        window.scrollTo({
            top: offsetPosition - 100,
            behavior: 'smooth'
        });
    }
};
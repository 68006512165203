import React, {ReactNode, useContext, useState} from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MultiSelector from "../../../../components/Multiselector";
import CustomDateRangePicker from "../DatePicker";
import VehicleSizeBoxes from "../../../../components/VehicleSizeBoxes";
import CheckBoxGroup from "../../../../components/CheckBoxGroup";
import Divider from "@mui/material/Divider";
import dayjs, {Dayjs} from "dayjs";
import parkingPropertyService from "../../../../api/ParkingPropertyApi";
import {AppContext, AppContextType} from "../../../../context/AppContextProvider";
import {useTranslation} from "react-i18next";
import GooglePlacesSearch_withBEApproach from "../GooglePlacesSearch/GooglePlacesSearch_withBEApproach";
import * as yup from 'yup';
import {useFormik} from "formik";
import PolicyAgreementText from "../../../Policies/PolicyAgreementText";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import {scrollToTarget} from "../../../../utils/Scroll";

const validationSchema = yup.object({
    location: yup
        .string()
        .trim()
        .required('yupValidation.requiredLocation'),
    startDateTime: yup
        .date()
        .required('yupValidation.requiredFieldGeneral'),
    endDateTime: yup
        .date()
        .required('yupValidation.requiredFieldGeneral')
        .min(yup.ref('startDateTime'), 'yupValidation.endDate')
        .test('is-not-equal', 'yupValidation.endDateDiff', function (value) {
            const {startDateTime} = this.parent;
            const start = dayjs(startDateTime);
            const end = dayjs(value);
            return !start.isSame(end, 'minute');
        })
        .test('min-step', 'Minimum step between start and end date is 30 minutes', function (value) {
            const {startDateTime} = this.parent;
            const start: Dayjs = dayjs(startDateTime);
            const end: Dayjs = dayjs(value);
            return end.diff(start, 'minutes') >= 30;
        }),
})

const Search = (): ReactNode => {
    const {filtrationFormData, updateContext}: AppContextType = useContext(AppContext);
    const {t} = useTranslation()

    const [accordionOpen, setAccordionOpen] = useState(false);

    console.log(filtrationFormData)

    const initialValues = {
        location: filtrationFormData.location,
        startDateTime: filtrationFormData.startDateTime || dayjs(),
        endDateTime: filtrationFormData.endDateTime || dayjs().add(1, 'hour'),
    };

    const onSubmit = async () => {
        const errors = await formik.validateForm();
        if (Object.keys(errors).length === 0) {
            let filtrationResult = await parkingPropertyService.getFilteredPropositions(filtrationFormData, 0);

            updateContext({
                filtrationResult: filtrationResult.data
            });

            scrollToTarget('parking-map');
        } else {
            console.log('Form has errors:', errors);
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit,
    });

    const handleChange = (event: any) => {
        const {name, value, type, checked} = event.target;
        const newValue = type === 'checkbox' ? checked : value;

        updateContext({
            filtrationFormData: {...filtrationFormData, [name]: newValue}
        });
    };

    const handleParkingTypeChange = (newParkingTypes: string[]) => {
        updateContext({
            filtrationFormData: {...filtrationFormData, 'parkingTypes': newParkingTypes || []}
        });
    }

    const handleLocationChange = (newLocation: any | null) => {

        updateContext({
            filtrationFormData: {
                ...filtrationFormData,
                'placeId': newLocation ? newLocation.place_id : "",
                'location': newLocation ? newLocation.description : ''
            }
        });

        formik.setFieldValue('location', newLocation ? newLocation.description : '');
    }

    const handleStartDateChange = (newStartDateTime: Dayjs | null) => {
        updateContext({
            filtrationFormData: {
                ...filtrationFormData,
                'startDateTime': newStartDateTime || dayjs(),
                'endDateTime': dayjs(newStartDateTime).add(1, 'day')
            }
        });

        formik.setFieldValue('startDateTime', newStartDateTime)
    }

    const handleEndDateChange = (newEndDateTime: Dayjs | null) => {
        updateContext({
            filtrationFormData: {...filtrationFormData, 'endDateTime': newEndDateTime || dayjs()}
        });
        formik.setFieldValue('endDateTime', newEndDateTime)
    };

    const toggleAccordion = () => {
        setAccordionOpen(!accordionOpen);
    };

    return (
        <Box
            id={'main-page-search-form'}
            padding={{xs: 3, sm: 6}}
            width={1}
            component={Card}
            boxShadow={1}
            data-aos="fade-up"
        >

            <form noValidate onSubmit={formik.handleSubmit}>
                <Box
                    display="flex"
                    flexDirection={{xs: 'column', md: 'row'}}
                >
                    <Box
                        width={1}
                        marginRight={{xs: 0, md: 2}}
                        marginBottom={{xs: 2, md: 2}}
                    >
                        <GooglePlacesSearch_withBEApproach
                            handleLocationChange={handleLocationChange}
                            formik={formik}
                        />
                    </Box>
                    <Box
                        width={1}
                        marginRight={{xs: 0, md: 2}}
                        marginBottom={{xs: 2, md: 2}}
                    >
                        <CustomDateRangePicker
                            handleStartDateChange={handleStartDateChange}
                            handleEndDateChange={handleEndDateChange}
                            formik={formik}
                        />
                    </Box>
                </Box>
                <Divider
                    sx={{
                        marginY: 2,
                    }}
                />
                <Accordion
                    expanded={accordionOpen}
                    onChange={toggleAccordion}
                    elevation={0}
                    sx={{
                        marginTop: accordionOpen ? 0 : 2,
                        '&:before': {
                            display: 'none',
                        }
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        sx={{
                            "&.Mui-expanded": {
                                minHeight: 0
                            },
                            "& .MuiAccordionSummary-content.Mui-expanded": {
                                margin: "12px 0"
                            }
                        }}
                    >
                        <Typography color={'text.secondary'}
                                    variant="subtitle2">{t("common.advancedFilters")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{padding: 0}}>
                        <Box display="flex" flexDirection={{xs: 'column', md: 'row'}}>
                            <Box width={1} marginRight={{xs: 0, md: 2}} marginBottom={{xs: 2, md: 2}}>
                                <MultiSelector handleParkingTypeChange={handleParkingTypeChange}
                                               formData={filtrationFormData}/>
                            </Box>
                            <Box width={1} marginRight={{xs: 0, md: 2}} marginBottom={{xs: 2, md: 2}}>
                                <VehicleSizeBoxes handleChange={handleChange} formData={filtrationFormData}/>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection={{xs: 'column', md: 'row'}} marginTop={{xs: 2, md: 2}}>
                            <Box>
                                <CheckBoxGroup
                                    handleChange={handleChange}
                                    lpgAllowed={filtrationFormData.lpgAllowed}
                                    guarded={filtrationFormData.guarded}
                                    chargerPresent={filtrationFormData.chargerPresent}
                                    accessible={filtrationFormData.accessible}
                                />
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Box
                    display="flex"
                    flexDirection={{xs: 'column', md: 'row'}}
                    marginTop={{xs: 2, md: 2}}
                >
                    <Button
                        sx={{height: 54, whiteSpace: 'nowrap'}}
                        variant="contained"
                        color="primary"
                        size="medium"
                        fullWidth
                        type={'submit'}
                    >
                        {t('common.getStarted')}
                    </Button>
                </Box>
            </form>
            <PolicyAgreementText/>
        </Box>
    );
};

export default Search;




import React, {ReactNode} from 'react';
import {Box, Button, IconButton, TextField, Typography} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs, {Dayjs} from 'dayjs';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import {useTranslation} from "react-i18next";
import {Slot} from "../../../types/api";
import {v4 as uuidv4} from "uuid";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {MobileDateTimePicker} from "@mui/x-date-pickers";

interface Props {
    formik: any
}

const Schedules = ({formik}: Props): ReactNode => {
    const {t} = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleAddClick = () => {
        formik.setFieldValue('schedules', [
            ...formik.values.schedules,
            {
                startDateTime: dayjs(),
                endDateTime: dayjs().add(1, 'day'),
                price: 0,
                slotId: uuidv4(),
            },
        ]);
    };

    const handleDeleteClick = (id: number) => {
        const updatedItems = formik.values.schedules.filter((_: any, index: number) => index !== id);
        formik.setFieldValue('schedules', updatedItems);
    };

    const handleStartDateChange = (id: number, date: Dayjs | null) => {
        const updatedItems = formik.values.schedules.map((item: any, index: number) =>
            index === id ? {...item, startDateTime: date} : item
        );
        formik.setFieldValue('schedules', updatedItems);
    };

    const handleEndDateChange = (id: number, date: Dayjs | null) => {
        const updatedItems = formik.values.schedules.map((item: any, index: number) =>
            index === id ? {...item, endDateTime: date} : item
        );
        formik.setFieldValue('schedules', updatedItems);
    };

    const handlePriceChange = (id: number, price: string) => {
        const updatedItems = formik.values.schedules.map((item: any, index: number) =>
            index === id ? {...item, price} : item
        );
        formik.setFieldValue('schedules', updatedItems);
    };

    return (
        <>
            <Box display={'flex'} justifyContent={"center"} textAlign="center" marginTop={{xs: 2, md: 2}}>
                <Typography color={'text.secondary'} textAlign="center"
                            variant="subtitle2">{t("common.parkingAvailability")}</Typography>
            </Box>
            {formik.values.schedules.map((item: Slot, index: number) => (
                <Box key={item.slotId} display={'flex'} flexDirection={{xs: 'column', sm: 'row'}}
                     marginTop={{xs: 2, md: 2}}>
                    <Box width={1} marginRight={{xs: 0, md: 2}} marginBottom={{xs: 2, md: 0}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Box display={'flex'} flexDirection={{xs: 'column', sm: 'row'}}>
                                <Box width={1} marginRight={{xs: 0, md: 2}} marginBottom={{xs: 2, md: 0}}>
                                    <MobileDateTimePicker
                                        name={'startDateTime'}
                                        sx={{width: '100%'}}
                                        minDate={dayjs()}
                                        label={t('common.startDateHost')}
                                        value={item.startDateTime}
                                        onChange={(newValue) => handleStartDateChange(index, newValue)}
                                        views={['year', 'month', 'day', 'hours', 'minutes']}
                                        format="DD-MM-YYYY HH:mm"
                                        ampm={false}
                                    />
                                    {formik.touched.schedules && formik.touched.schedules[index] && formik.errors.schedules && formik.errors.schedules[index] && formik.errors.schedules[index].startDateTime && (
                                        <Typography variant="caption" color="error" marginLeft={2}>
                                            {t(formik.errors.schedules[index].startDateTime)}
                                        </Typography>
                                    )}
                                </Box>
                                <Box width={1} marginBottom={{xs: 2, md: 0}}>
                                    <MobileDateTimePicker
                                        name={'endDateTime'}
                                        sx={{width: '100%', borderColor: '#fcba03'}}
                                        minDate={item.startDateTime || dayjs()}
                                        label={t('common.endDateHost')}
                                        value={item.endDateTime}
                                        onChange={(newValue) => handleEndDateChange(index, newValue)}
                                        views={['year', 'month', 'day', 'hours', 'minutes']}
                                        format="DD-MM-YYYY HH:mm"
                                        ampm={false}
                                    />
                                    {formik.touched.schedules && formik.touched.schedules[index] && formik.errors.schedules && formik.errors.schedules[index] && formik.errors.schedules[index].endDateTime && (
                                        <Typography variant="caption" color="error" marginLeft={2} noWrap>
                                            {t(formik.errors.schedules[index].endDateTime)}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        </LocalizationProvider>
                    </Box>
                    <Box marginRight={{xs: 0, md: 2}} marginBottom={{xs: 2, md: 0}}>
                        <TextField
                            label={t('share.price')}
                            variant="outlined"
                            name={'price'}
                            fullWidth
                            value={item.price}
                            onChange={(e) => handlePriceChange(index, e.target.value)}
                            onBlur={formik.handleBlur}
                            error={formik.touched.schedules && formik.touched.schedules[index] && formik.errors.schedules && formik.errors.schedules[index] && formik.errors.schedules[index].price}
                            helperText={formik.touched.schedules && formik.touched.schedules[index] && formik.errors.schedules && formik.errors.schedules[index] && t(formik.errors.schedules[index].price)}
                        />
                    </Box>
                    <Box display={'flex'} justifyContent={"center"} marginBottom={{xs: 2, md: 0}}>
                        <Typography color={'text.secondary'}
                                    variant="subtitle2">{t("common.scheduleTip")}</Typography>
                    </Box>
                    <Box marginRight={{xs: 0, md: 2}} marginBottom={{xs: 2, md: 0}}>
                        {index === 0 ? (
                            isMobile ? (
                                <Button fullWidth variant="outlined" color={'primary'} size={'small'}
                                        startIcon={<AddIcon/>} onClick={handleAddClick}>
                                    {t("common.add")}
                                </Button>
                            ) : (
                                <IconButton onClick={handleAddClick} color="primary" aria-label="add">
                                    <AddIcon/>
                                </IconButton>
                            )
                        ) : (
                            isMobile ? (
                                <Button fullWidth variant="outlined" color={'warning'} size={'small'}
                                        startIcon={<DeleteIcon/>} onClick={() => handleDeleteClick(index)}>
                                    {t("common.delete")}
                                </Button>
                            ) : (
                                <IconButton onClick={() => handleDeleteClick(index)} color="warning"
                                            aria-label="delete">
                                    <DeleteIcon/>
                                </IconButton>
                            )
                        )}
                    </Box>
                </Box>
            ))}
        </>
    );
};

export default Schedules;

import React, {useContext} from 'react';
import {useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Container from "../components/Container";
import pages from "./navigation";
import Topbar from "./Topbar";
import Footer from "./Footer";
import {LinearProgress} from "@mui/material";
import {ResourceLoaderContext, ResourceLoaderContextType} from "../components/ResourceLoaderContext";

interface Props {
    children: React.ReactNode;
    colorInvert?: boolean;
    bgcolor?: string;
}

const Main = ({
                  children,
                  colorInvert = false,
                  bgcolor = 'transparent',
              }: Props): JSX.Element => {
    const theme = useTheme();
    const {isGoogleMapsLoaded, isLoaded}: ResourceLoaderContextType = useContext(ResourceLoaderContext);
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 38,
    });

    return (
        <Box>
            <AppBar
                id={'app_bar'}
                position={'sticky'}
                sx={{
                    top: 0,
                    backgroundColor: trigger ? theme.palette.background.paper : bgcolor,
                }}
                elevation={trigger ? 1 : 0}
            >

                {
                    (!isGoogleMapsLoaded || !isLoaded) &&
                    <Box>
                        <LinearProgress/>
                    </Box>
                }

                <Container paddingY={1}>
                    <Topbar
                        pages={pages}
                        colorInvert={trigger ? false : colorInvert}
                    />
                </Container>
            </AppBar>

            <main>
                {children}
                <Divider/>
            </main>
            <Container paddingY={4}>
                <Footer/>
            </Container>
        </Box>
    );
};

export default Main;

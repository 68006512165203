import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {useTheme} from '@mui/material/styles';
import {scrollToTarget} from "../../utils/Scroll";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface PageItem {
    title: string;
    href: string;
}

interface Props {
    pages: {
        landings: Array<PageItem>;
        company: Array<PageItem>;
        account: Array<PageItem>;
        secondary: Array<PageItem>;
        blog: Array<PageItem>;
        portfolio: Array<PageItem>;
    };
    colorInvert?: boolean;
}

const Topbar = ({
                    colorInvert = false,
                }: Props): JSX.Element => {
    const theme = useTheme();
    const {mode} = theme.palette;
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const handleShareAParkingClick = () => {
        navigate('/host-add-offer');
    };

    const handleNeedAParkingClick = () => {
        const isHomePage = location.pathname === '/';

        if (isHomePage) {
            scrollToTarget('main-page-search-form');
        } else {
            navigate('/');
            //TODO: add this scrolling properlly
            // scrollToTarget('main-page-search-form');
        }
    }

    const getLogo = () => {
        const isHomePage = location.pathname === '/';

        if (isHomePage) {
            return mode === 'light' && !colorInvert
                ? '/media/logo_transparent.png'
                : '/media/logo_transparent_white.png'
        } else {
            return '/media/logo_transparent.png'
        }
    }

    return (
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={1}
        >
            <Box
                display={'flex'}
                component="a"
                href="/"
                width={{xs: 100, md: 120}}
            >
                <Box
                    component={'img'}
                    src={getLogo()}
                    sx={{
                        height: '150%', // Double the size using percentage
                        width: '150%',  // Double the size using percentage
                    }}
                />
            </Box>
            <Box sx={{display: {xs: 'none', md: 'flex'}}} alignItems={'center'}>
                <Box marginLeft={4}>
                    <Button
                        variant="contained"
                        color="primary"
                        component="a"
                        target="blank"
                        size="large"
                        onClick={handleNeedAParkingClick}
                    >
                        {t('common.needAParking')}
                    </Button>
                </Box>
                <Box marginLeft={4}>
                    <Button
                        onClick={handleShareAParkingClick}
                        variant="contained"
                        color="secondary"
                        component="a"
                        target="blank"
                        size="large"
                    >
                        {t('common.shareAParking')}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default Topbar;

import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material/styles';
import P24Logo from "./P24Logo";
import SocialNetworks from "./SocialNetworks";
import {useTranslation} from "react-i18next";
import {styled} from "@mui/material";

const Footer = (): JSX.Element => {
    const {t} = useTranslation();
    const theme = useTheme();
    const {mode} = theme.palette;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    width={1}
                    flexDirection={{xs: 'row', sm: 'row'}}
                >
                    <Box
                        display={'flex'}
                        component="a"
                        href="/"
                        title="theFront"
                        width={80}
                    >
                        <Box
                            component={'img'}
                            src={
                                mode === 'light'
                                    ? '/media/logo_transparent.png'
                                    : '/media/logo_transparent_white.png'
                            }
                            height={1}
                            width={1}
                        />
                    </Box>
                    <SocialNetworks/>
                    <P24Logo/>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Typography
                    align={'center'}
                    variant={'subtitle2'}
                    color="text.secondary"
                    gutterBottom
                >
                    Powered by &copy;&nbsp;
                    <Link
                        href="https://orliks.com"
                        color={theme.palette.primary.main}
                        underline="none"
                        sx={{
                            transition: 'all 0.3s ease',
                            '&:hover': {
                                fontWeight: 'bold',
                                color: theme.palette.secondary.main,
                            },
                        }}
                    >
                        Orliks
                    </Link>, 2024
                </Typography>
                <Typography
                    align={'center'}
                    variant={'caption'}
                    color="text.secondary"
                    component={'p'}
                >
                    {t('common.cookies')}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Footer;

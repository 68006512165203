import React, {useContext, useEffect} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {scrollToTarget} from "../../../../utils/Scroll";
import {useTranslation} from "react-i18next";
import parkingPropertyService from "../../../../api/ParkingPropertyApi";
import {AppContext, AppContextType} from "../../../../context/AppContextProvider";
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from "@mui/material/styles";

export const Car = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        enableBackground="new 0 0 20 20"
        viewBox="0 0 20 20"
        fill="#ffb74d">
        <path
            d="M14.55,8.53l-0.83-2.49c-0.31-0.92-1.17-1.54-2.13-1.54H8.41c-0.97,0-1.83,0.62-2.13,1.54L5.73,7.67l-1-1l0.15-0.15 c0.29-0.29,0.29-0.77,0-1.06s-0.77-0.29-1.06,0L2.47,6.82c-0.29,0.29-0.29,0.77,0,1.06c0.29,0.29,0.77,0.29,1.06,0l0.14-0.14 l0.99,0.99C3.69,9.15,3,10.12,3,11.25c0,0.98,0.51,1.83,1.28,2.32c0.16,1.09,1.09,1.93,2.22,1.93c0.98,0,1.8-0.63,2.11-1.5h2.78 c0.31,0.87,1.14,1.5,2.11,1.5c1.13,0,2.06-0.84,2.22-1.93c0.77-0.49,1.28-1.34,1.28-2.32C17,9.84,15.92,8.68,14.55,8.53z M10.75,6 h0.84c0.32,0,0.61,0.21,0.71,0.51l0.66,1.99h-2.21V6z M7.7,6.51C7.81,6.21,8.09,6,8.41,6h0.84v2.5H7.04L7.7,6.51z M6.5,14 c-0.41,0-0.75-0.34-0.75-0.75S6.09,12.5,6.5,12.5s0.75,0.34,0.75,0.75S6.91,14,6.5,14z M13.5,14c-0.41,0-0.75-0.34-0.75-0.75 s0.34-0.75,0.75-0.75s0.75,0.34,0.75,0.75S13.91,14,13.5,14z M15.29,11.9c-0.41-0.54-1.06-0.9-1.79-0.9c-0.98,0-1.8,0.63-2.11,1.5 H8.61C8.3,11.63,7.48,11,6.5,11c-0.73,0-1.38,0.36-1.79,0.9c-0.12-0.19-0.21-0.41-0.21-0.65C4.5,10.56,5.06,10,5.75,10h8.5 c0.69,0,1.25,0.56,1.25,1.25C15.5,11.49,15.41,11.71,15.29,11.9z"/>
    </svg>
);

const NoFiltrationResult = (): JSX.Element => {
    const {updateContext, filtrationFormData}: AppContextType = useContext(AppContext);
    const {t} = useTranslation()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const svgSize = isMobile ? 200 : 300;

    useEffect(() => {
        scrollToTarget('empty-search-result');

    }, []);

    const handleClick = async () => {
        const formDataCopy = Object.assign({}, filtrationFormData);
        formDataCopy.placeId = "";

        let filtrationResult = await parkingPropertyService.getFilteredPropositions(formDataCopy, 0);

        updateContext({
            filtrationResult: filtrationResult.data
        });

    };

    return (
        <Box>
            <Box bgcolor={'alternate.main'}>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Box
                        data-aos={'fade-up'}
                        marginBottom={{xs: 2, md: 2}}
                        sx={{
                            width: svgSize,
                            height: 'auto',
                            '& svg': {
                                width: '100%',
                                height: 'auto',
                            },
                        }}
                    >
                        {Car}
                    </Box>
                    <Box id={"empty-search-result"}>
                        <Typography
                            variant="h4"
                            align={'center'}
                            data-aos={'fade-up'}
                            gutterBottom
                            sx={{
                                fontWeight: 700,
                            }}
                        >
                            {t('common.noResultTitle')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            align={'center'}
                            data-aos={'fade-up'}
                            gutterBottom
                        >
                            {t('common.noResultSubTitle')}
                        </Typography>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection={{xs: 'column', md: 'row'}}
                        marginTop={{xs: 2, md: 5}}
                    >
                        <Button
                            sx={{height: 54, whiteSpace: 'nowrap'}}
                            variant="contained"
                            color="primary"
                            size="medium"
                            fullWidth
                            type={'submit'}
                            onClick={handleClick}
                        >
                            {t('common.findAll')}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default NoFiltrationResult;

import React, {ReactNode, useContext, useRef, useEffect} from "react";
import {GoogleMap, InfoWindow, Marker} from "@react-google-maps/api";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import {AppContext, AppContextType} from "../../context/AppContextProvider";
import {Offer} from "../../types/api";
import CustomMarkerInfo from "./CustomMarkerInfo";

const MainMap = (): ReactNode => {
    const mapRef = useRef<google.maps.Map | null>(null);
    const [selectedOfferMarker, setSelectedOfferMarker] = React.useState<Offer | null>(null);
    const {filtrationResult}: AppContextType = useContext(AppContext);
    const {offers, selectedPlace} = filtrationResult;

    const handleMarkerClick = (offer: Offer) => {
        setSelectedOfferMarker(offer);
    };

    useEffect(() => {
        if (mapRef.current && offers.content.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();
            offers.content.forEach((offer: Offer) => {
                bounds.extend(offer.position);
            });
            mapRef.current.fitBounds(bounds);
        }
    }, [offers.content]);

    const onLoad = (map: google.maps.Map) => {
        mapRef.current = map;
    };

    return (
        <Box
            marginBottom={{xs: 2, md: 6}}
            width={1}
            component={Card}
            boxShadow={1}
            data-aos="fade-up"
        >
            <GoogleMap
                mapContainerStyle={{width: "100%", height: "400px"}}
                center={selectedPlace}
                zoom={10} // Set a default zoom level
                onLoad={onLoad}
            >
                {offers.content.map((offer: Offer) => (
                        <Marker
                            key={offer.id}
                            position={offer.position}
                            onClick={() => handleMarkerClick(offer)}
                        />
                    )
                )}
                {selectedOfferMarker && (
                    <InfoWindow
                        position={selectedOfferMarker.position}
                        onCloseClick={() => setSelectedOfferMarker(null)}
                    >
                        <CustomMarkerInfo offer={selectedOfferMarker}/>
                    </InfoWindow>
                )}
            </GoogleMap>
        </Box>
    );
};

export default MainMap;
import { useEffect, useState } from "react";

interface StateInterface {
    errors: Array<string>,
}

function createStore(initialState: StateInterface) {
    let currentState = initialState;
    const listeners = new Set();
    return {
        getState: () => currentState,
        setState: (newState: any) => {
            currentState = newState;
            listeners.forEach((listener: any) => listener(currentState));
        },
        subscribe: (listener: any) => {
            listeners.add(listener);
            return () => listeners.delete(listener);
        },
        clearLastError: () => {
            currentState.errors.pop();
            listeners.forEach((listener: any) => listener(currentState));
        },
    };
}

const store = createStore({
    errors: new Array<string>(),
});

const useStore = (selector = (state: any) => state) => {
    const [state, setState] = useState(selector(store.getState()));

    useEffect(() => {
        store.subscribe((state: any) => setState(selector(state)));
    }, []);

    return state;
};

function setErrorToGlobalStore(err: string) {
    const state = store.getState();
    store.setState({
        ...state,
        errors: [...state.errors, err],
    });
}

export {
    store,
    useStore,
    setErrorToGlobalStore,
};

import React from "react";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Box from "@mui/material/Box";
import ListItemText from "@mui/material/ListItemText";
import {Offer} from "../../../../types/api";
import {AccessibleParking, ElectricCarCharger, GasPoweredAllowed, GuardedParking,} from "./Icons/Icons";
import Divider from "@mui/material/Divider";

interface AdditionalFeaturesProps {
    currentOfferDetails: Offer;
}

const AdditionalFeatures: React.FunctionComponent<AdditionalFeaturesProps> = ({
                                                                                  currentOfferDetails,
                                                                              }) => {
    const {lpgAllowed, chargerPresent, guarded, accessible} = currentOfferDetails;

    return (
        <>
            {
                (lpgAllowed || chargerPresent || guarded || accessible) && <Divider sx={{marginY: 2}}/>
            }
            <Grid container spacing={2} sx={{flexDirection: {xs: 'column', sm: 'row'}}}>
                {guarded && (
                    <Grid item xs={12} sm={6}>
                        <ListItem
                            component="div"
                            disableGutters
                            sx={{
                                alignItems: "flex-start",
                                padding: 0,
                            }}
                        >
                            <ListItemAvatar sx={{minWidth: 0, mr: 1}}>
                                <Box color={"text.secondary"}>{GuardedParking}</Box>
                            </ListItemAvatar>
                            <ListItemText
                                primaryTypographyProps={{
                                    variant: "body2",
                                    fontWeight: 700,
                                }}
                                secondaryTypographyProps={{
                                    variant: "caption",
                                }}
                                sx={{
                                    margin: 0,
                                }}
                            />
                        </ListItem>
                    </Grid>
                )}
                {accessible && (
                    <Grid item xs={12} sm={6}>
                        <ListItem
                            component="div"
                            disableGutters
                            sx={{
                                alignItems: "flex-start",
                                padding: 0,
                            }}
                        >
                            <ListItemAvatar sx={{minWidth: 0, mr: 1}}>
                                <Box color={"text.secondary"}>{AccessibleParking}</Box>
                            </ListItemAvatar>
                            <ListItemText
                                primaryTypographyProps={{
                                    variant: "body2",
                                    fontWeight: 700,
                                }}
                                secondaryTypographyProps={{
                                    variant: "caption",
                                }}
                                sx={{
                                    margin: 0,
                                }}
                            />
                        </ListItem>
                    </Grid>
                )}
                {lpgAllowed && (
                    <Grid item xs={12} sm={6}>
                        <ListItem
                            component="div"
                            disableGutters
                            sx={{
                                alignItems: "flex-start",
                                padding: 0,
                            }}
                        >
                            <ListItemAvatar sx={{minWidth: 0, mr: 1}}>
                                <Box color={"text.secondary"}>{GasPoweredAllowed}</Box>
                            </ListItemAvatar>
                            <ListItemText
                                primaryTypographyProps={{
                                    variant: "body2",
                                    fontWeight: 700,
                                }}
                                secondaryTypographyProps={{
                                    variant: "caption",
                                }}
                                sx={{
                                    margin: 0,
                                }}
                            />
                        </ListItem>
                    </Grid>
                )}
                {chargerPresent && (
                    <Grid item xs={12} sm={6}>
                        <ListItem
                            component="div"
                            disableGutters
                            sx={{
                                alignItems: "flex-start",
                                padding: 0,
                            }}
                        >
                            <ListItemAvatar sx={{minWidth: 0, mr: 1}}>
                                <Box color={"text.secondary"}>{ElectricCarCharger}</Box>
                            </ListItemAvatar>
                            <ListItemText
                                primaryTypographyProps={{
                                    variant: "body2",
                                    fontWeight: 700,
                                }}
                                secondaryTypographyProps={{
                                    variant: "caption",
                                }}
                                sx={{
                                    margin: 0,
                                }}
                            />
                        </ListItem>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default AdditionalFeatures;

export const FailImage = <svg xmlns="http://www.w3.org/2000/svg" width="121" height="121" viewBox="0 0 121 121">
    <g id="Group_38070" data-name="Group 38070" transform="translate(-900 -357)">
        <g id="Group_37983" data-name="Group 37983" transform="translate(-3.896 -6.896)">
            <circle id="Ellipse_21417" data-name="Ellipse 21417" cx="60.5" cy="60.5" r="60.5"
                    transform="translate(903.896 363.896)" fill="#f40303"/>
            <path id="Path_68857" data-name="Path 68857" d="M2940.2,13830.083v33.4"
                  transform="translate(-1975.057 -13433.927)" fill="none" stroke="#fff" stroke-width="10"/>
            <path id="Path_68858" data-name="Path 68858" d="M2940.2,13830.083v11.287"
                  transform="translate(-1975.057 -13391.649)" fill="none" stroke="#fff" stroke-width="10"/>
        </g>
    </g>
</svg>

import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {FiltrationBody} from "../types/api";
import {useTranslation} from "react-i18next";

interface Props {
    handleChange(event: React.ChangeEvent<HTMLInputElement>): void,

    formData: FiltrationBody
}


const VehicleSizeBoxes: React.FunctionComponent<Props> = ({
                                                              handleChange,
                                                              formData
                                                          }) => {
    const {t} = useTranslation()
    const {spotLength, spotWidth, spotHeight} = formData;

    return (
        <Box
            display="flex"
            justifyContent="space-between"
        >
            <Box sx={{flex: 1, marginRight: 2}}>
                <TextField
                    disabled
                    value={spotLength}
                    onChange={handleChange}
                    name={'spotLength'}
                    type="number"
                    variant="outlined"
                    label={t('common.length')}
                />
            </Box>
            <Box sx={{flex: 1, margin: '0 2px'}}>
                <TextField
                    disabled
                    value={spotWidth}
                    onChange={handleChange}
                    name={'spotWidth'}
                    type="number"
                    variant="outlined"
                    label={t('common.width')}
                />
            </Box>
            <Box sx={{flex: 1, marginLeft: 2}}>
                <TextField
                    disabled
                    value={spotHeight}
                    onChange={handleChange}
                    name={'spotHeight'}
                    type="number"
                    variant="outlined"
                    label={t('common.height')}
                />
            </Box>
        </Box>
    );
}

export default VehicleSizeBoxes;
import React, {ReactNode, useEffect} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MiniMap from "../Map";
import {scrollToTarget} from "../../utils/Scroll";
import {ApiResponse, Offer} from "../../types/api";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {useFormik} from "formik";
import checkoutApi from "../../api/CheckoutApi";

interface CheckoutDetailsProps {
    currentOfferDetails: Offer,
}

const latinWithDiacriticsRegex = /^[a-zA-Z\u00C0-\u017F]*$/;

const validationSchema = yup.object({
    firstName: yup
        .string()
        .trim()
        .min(2, 'yupValidation.minFieldCharAmount')
        .max(50, 'yupValidation.maxFieldCharAmount')
        .matches(latinWithDiacriticsRegex, 'yupValidation.onlyLatinLetters')
        .required('yupValidation.requiredFieldGeneral'),
    lastName: yup
        .string()
        .trim()
        .min(2, 'yupValidation.minFieldCharAmount')
        .max(50, 'yupValidation.maxFieldCharAmount')
        .matches(latinWithDiacriticsRegex, 'yupValidation.onlyLatinLetters')
        .required('yupValidation.requiredFieldGeneral'),
    email: yup
        .string()
        .trim()
        .email('yupValidation.reqEmail')
        .required('yupValidation.requiredFieldGeneral'),
    phone: yup
        .string()
        .trim()
        .required('yupValidation.requiredFieldGeneral')
        .matches(
            /^\+?[1-9]{1}[0-9]{1,14}$/,
            'yupValidation.reqPhoneNumber'
        ),
    message: yup
        .string()
        .max(500, 'yupValidation.maxMessageLength')
});

const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    offerId: "",
    startTime: "",
    endTime: "",
    message: ""
};

interface CheckoutResponseData {
    redirectUrl: string;
}

const CheckoutDetails = ({currentOfferDetails}: CheckoutDetailsProps): ReactNode => {
    const {t} = useTranslation();

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const errors = await formik.validateForm();
            if (Object.keys(errors).length === 0) {
                const checkoutPayload = {
                    ...values,
                    offerId: currentOfferDetails.id,
                    startTime: currentOfferDetails.requestedStartDateTime,
                    endTime: currentOfferDetails.requestedEndDateTime
                };

                try {
                    const response = await checkoutApi.sendCheckoutDetails(checkoutPayload);
                    const responseData = response as unknown as ApiResponse<CheckoutResponseData>;
                    if (responseData.success && responseData.data !== undefined) {
                        window.location.href = responseData.data.redirectUrl;
                    } else {
                        console.error('Checkout failed:', responseData);
                    }
                } catch (error) {
                    console.error('Error sending checkout details:', error);
                }
            } else {
                console.log('Form has errors:', errors);
            }
        },
    });

    useEffect(() => {
        setTimeout(() => {
            scrollToTarget('reservation-information');
        }, 100);
    }, []);

    return (
        <Box>
            <form noValidate onSubmit={formik.handleSubmit}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={7}>
                        <Typography variant="h6" fontWeight={700} marginBottom={4} id={'reservation-information'}>
                            {t('common.resevationForm')}
                        </Typography>
                        <Box>
                            <Grid container spacing={{xs: 2, md: 4}}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        label={t('share.name')}
                                        variant="outlined"
                                        name="firstName"
                                        fullWidth
                                        value={formik.values.firstName}
                                        onChange={formik.handleChange}
                                        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                        helperText={formik.touched.firstName && t(formik.errors.firstName || '')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        label={t('share.surname')}
                                        variant="outlined"
                                        name="lastName"
                                        fullWidth
                                        value={formik.values.lastName}
                                        onChange={formik.handleChange}
                                        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                        helperText={formik.touched.lastName && t(formik.errors.lastName || '')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        label="Email"
                                        variant="outlined"
                                        name="email"
                                        fullWidth
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={
                                        (formik.touched.email && t(formik.errors.email || '')) ||
                                            t('share.emailNote')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={t('share.phoneNumber')}
                                        required
                                        variant="outlined"
                                        name="phone"
                                        fullWidth
                                        value={formik.values.phone}
                                        onChange={formik.handleChange}
                                        error={formik.touched.phone && Boolean(formik.errors.phone)}
                                        helperText={formik.touched.phone && t(formik.errors.phone || '')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={t('common.messageToParkingOwner')}
                                        multiline
                                        rows={6}
                                        variant="outlined"
                                        color="primary"
                                        size="medium"
                                        name="message"
                                        fullWidth
                                        value={formik.values.message}
                                        onChange={formik.handleChange}
                                        inputProps={{maxLength: 1000}}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <MiniMap position={currentOfferDetails.position}/>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            size={'large'}
                            fullWidth
                            type={'submit'}
                        >
                            {t('common.proceedToPayment')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default CheckoutDetails;

import {createContext} from 'react';

export interface ResourceLoaderContextType {
    isGoogleMapsLoaded: boolean;
    setGoogleMapsLoaded: (loaded: boolean) => void;
    isLoaded: boolean;
    setLoaded: (loaded: boolean) => void;
    loadError?: Error;
    setLoadError: (error: Error | undefined) => void;
}

export const ResourceLoaderContext = createContext<ResourceLoaderContextType>({
    isGoogleMapsLoaded: false,
    setGoogleMapsLoaded: () => {
    },
    isLoaded: true,
    setLoaded: () => {
    },
    setLoadError: () => {
    },
});

import CommonServerError from "../errors/CommonServerError";
import {setErrorToGlobalStore} from "../store/store";
import {fetchOptions} from "../constants";
import {ApiResponse} from "../types/api";

class CommonApiService {
    baseUrl = process.env.REACT_APP_BASE_URL;

    private async handleResponse<T>(response: Response): Promise<T> {
        if (response.ok) {
            const text = await response.text();
            return text ? JSON.parse(text) as T : {} as T;
        } else {
            const errorResponse = await response.json();
            throw new CommonServerError(errorResponse);
        }
    }

    async doRestCall<T>(path: string, options: RequestInit): Promise<ApiResponse<T>> {
        try {
            const response = await fetch(path, options);
            const data = await this.handleResponse<T>(response);
            return { success: true, data };
        } catch (error) {
            if (error instanceof CommonServerError) {
                const errResponse = error.errorResponse;
                setErrorToGlobalStore(`${errResponse.status}: ${errResponse.message}`);
            } else {
                setErrorToGlobalStore("Unexpected server error, please contact support team.");
            }
            return { success: false, error: "An error occurred" };
        }
    }

    async get<T>(path: string, options?: any): Promise<ApiResponse<T>> {
        return this.doRestCall<T>(path, { method: 'GET', ...fetchOptions, ...options });
    }

    async post<T>(path: string, body: any, options?: RequestInit): Promise<ApiResponse<T>> {
        return this.doRestCall<T>(path, {
            method: 'POST',
            body: JSON.stringify(body),
            ...fetchOptions,
            ...options,
        });
    }

    async customPost<T>(path: string, body: any, options?: RequestInit): Promise<ApiResponse<T>> {
        const customFetchOptions = {
            headers: {
                "Accept": "text/plain",
                "Content-Type": "application/x-www-form-urlencoded"
            },
        };

        return this.doRestCall<T>(path, {
            method: 'POST',
            body: new URLSearchParams(Object.entries(body)).toString(),
            ...customFetchOptions,
            ...options,
        });
    }

    async postBodyAndFile<T>(path: string, body: FormData, options?: RequestInit): Promise<ApiResponse<T>> {
        const fetchOptions = {
            headers: {
                "Accept": "application/json"
            },
        };

        return this.doRestCall<T>(path, {
            method: 'POST',
            body: body,
            ...fetchOptions,
            ...options,
        });
    }
}

export default CommonApiService;

import React, {ReactNode, useContext, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import {AppContext, AppContextType} from "../../context/AppContextProvider";
import SuccessfullySentFeedback from "./SuccessfullySentFeedback";
import FeedbackFormContent from "./FeedbackFormContent";

const FeedbackForm = (): ReactNode => {
    const [isFeedbackSent, setFeedbackSent] = useState(false);
    const {feedbackDialogIsOpen, updateContext}: AppContextType = useContext(AppContext);

    const onClose = () => {
        updateContext({
            feedbackDialogIsOpen: false
        });
        setFeedbackSent(false)
    }

    return (
        <Dialog
            onClose={onClose}
            open={feedbackDialogIsOpen}
            maxWidth={'sm'}
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: 2,
                    minHeight: '65vh'
                },
            }}
        >
            {
                isFeedbackSent
                    ? (<SuccessfullySentFeedback onClose={onClose}/>)
                    : (<FeedbackFormContent onClose={onClose} setFeedbackSent={setFeedbackSent}/>)
            }
        </Dialog>
    );
};

export default FeedbackForm;

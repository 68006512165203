import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import {Checkbox} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import {FiltrationBody} from "../types/api";
import {useTranslation} from "react-i18next";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'underground',
    'garage',
    'openair'
];

interface Props {
    handleParkingTypeChange(newParkingTypes: string[]): void,

    formData: FiltrationBody
}

const Multiselector: React.FunctionComponent<Props> = ({
                                                           handleParkingTypeChange,
                                                           formData
                                                       }) => {
    const {t} = useTranslation()
    const {parkingTypes} = formData;
    const handleSelectChange = (event: SelectChangeEvent<typeof parkingTypes>) => {
        const {target: {value}} = event;

        handleParkingTypeChange(typeof value === 'string' ? value.split(',') : value)
    };

    return (
        <div>
            <FormControl fullWidth disabled>
                <InputLabel id="demo-multiple-checkbox-label">{t('common.parkingType')}</InputLabel>
                <Select
                    name={'parkingType'}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={parkingTypes}
                    onChange={handleSelectChange}
                    input={<OutlinedInput label="parking_type"/>}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                >
                    {names.map((name) => (
                        <MenuItem key={name} value={name}>
                            <Checkbox checked={parkingTypes.indexOf(name) > -1}/>
                            <ListItemText primary={t(`common.${name}`)}/>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}

export default Multiselector;

import React, {ReactNode, useState, useRef, useContext} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import {Link} from "react-router-dom";
import {Offer} from "../../../../types/api";
import {useTranslation} from "react-i18next";
import {isPriceWithPromo} from "../../../../utils/CostCalc";
import Skeleton from '@mui/material/Skeleton';
import {AppContext, AppContextType} from "../../../../context/AppContextProvider";
import {SvgIcon} from "@mui/material";
import {useTheme} from "@mui/material/styles";

interface OfferCardProps {
    offer: Offer,
    key: number
}

const baseUrl = process.env.REACT_APP_BASE_URL;

const OfferCard = ({offer, key}: OfferCardProps): ReactNode => {
    const {filtrationFormData}: AppContextType = useContext(AppContext);
    const {startDateTime, endDateTime} = filtrationFormData;
    const {availableForBooking} = offer;
    const {t} = useTranslation();
    const [imageLoaded, setImageLoaded] = useState(false);
    const [imageError, setImageError] = useState(false);
    const imageRef = useRef<HTMLImageElement>(null);
    const theme = useTheme();

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    const handleImageError = () => {
        setImageError(true);
    };

    const hasMedia: boolean = offer.media && offer.media.length > 0;

    const imageHeight = 240;

    return (
        <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={key}
            // data-aos={'fade-up'}
            // data-aos-delay={key * 50}
            // data-aos-delay={0}
            // data-aos-offset={-100}
            // data-aos-duration={300}
        >
            <Box display={'block'} width={1} height={1}>
                <Box
                    component={Card}
                    width={1}
                    height={1}
                    display={'flex'}
                    flexDirection={'column'}
                >
                    {(!hasMedia || !imageLoaded || imageError) && (
                        <Skeleton variant="rectangular" height={imageHeight}/>
                    )}
                    {hasMedia && !imageError && (
                        <Box sx={{position: 'relative', height: imageHeight}}>
                            <CardMedia
                                component="img"
                                title={offer.title}
                                image={baseUrl + offer.media[0].url}
                                sx={{
                                    height: '100%',
                                    overflow: 'hidden',
                                    display: imageLoaded ? 'block' : 'none',
                                }}
                                onLoad={handleImageLoad}
                                onError={handleImageError}
                                ref={imageRef}
                            />
                            {imageLoaded && !availableForBooking && (
                                <Box
                                    display={'flex'}
                                    justifyContent={'space-between'}
                                    position={'absolute'}
                                    bottom={0}
                                    padding={2}
                                    width={1}
                                >
                                    <Box
                                        padding={1}
                                        boxShadow={1}
                                        borderRadius={2}
                                        display="flex"
                                        alignItems="center"
                                        bgcolor={theme.palette.secondary.main}
                                    >
                                        <SvgIcon>
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"
                                            />
                                        </SvgIcon>
                                        <Typography sx={{fontWeight: 600, marginLeft: 1}}>
                                            {t("common.reserved")}
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    )}
                    <CardContent>
                        <Box display={'flex'} alignItems={'center'} marginY={2}>
                            <Box
                                component={'svg'}
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                width={16}
                                height={16}
                                marginRight={1}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                />
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                            </Box>
                            <Typography variant={'subtitle2'} color="text.secondary">
                                {offer.offerAddress}
                            </Typography>
                        </Box>
                        <Box display={'flex'} alignItems={'center'}>
                            <Box
                                component={'svg'}
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                width={16}
                                height={16}
                                marginRight={1}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                                />
                            </Box>
                            <Typography variant={'subtitle2'} color="text.secondary">
                                {`${offer.spotLength} x ${offer.spotWidth} x ${offer.spotHeight}`}
                            </Typography>
                        </Box>

                        {
                            availableForBooking && (
                                <Box display="flex" flexDirection="column" marginY={2}>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                    >
                                        <Box
                                            component={'svg'}
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            width={16}
                                            height={16}
                                            marginRight={1}
                                            fill="#00000"
                                        >
                                            <path
                                                fill="#00000"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={0.1}
                                                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm.31-8.86c-1.77-.45-2.34-.94-2.34-1.67 0-.84.79-1.43 2.1-1.43 1.38 0 1.9.66 1.94 1.64h1.71c-.05-1.34-.87-2.57-2.49-2.97V5H10.9v1.69c-1.51.32-2.72 1.3-2.72 2.81 0 1.79 1.49 2.69 3.66 3.21 1.95.46 2.34 1.15 2.34 1.87 0 .53-.39 1.39-2.1 1.39-1.6 0-2.23-.72-2.32-1.64H8.04c.1 1.7 1.36 2.66 2.86 2.97V19h2.34v-1.67c1.52-.29 2.72-1.16 2.73-2.77-.01-2.2-1.9-2.96-3.66-3.42z"
                                            />
                                        </Box>
                                        {isPriceWithPromo(offer.costDetails) ?
                                            (
                                                <Typography variant={'subtitle2'} color="text.primary" fontWeight={'bold'}>
                                                    {`${t('price.totalWithFee')} `}
                                                    <span style={{
                                                        fontWeight: 'bold',
                                                        textDecoration: 'line-through',
                                                        color: 'red',
                                                    }}>
                                                {`${offer.costDetails.total} zł`}
                                            </span>
                                                    {` ${offer.costDetails.totalAfterPromo} zł *`}
                                                </Typography>
                                            ) :
                                            (
                                                <Typography variant={'subtitle2'} color="text.primary" fontWeight={'bold'}>
                                                    {`${t('price.totalWithFee')} ${offer.costDetails.totalAfterPromo} zł`}
                                                </Typography>
                                            )
                                        }
                                    </Box>
                                </Box>
                            )
                        }
                        <CardActions sx={{justifyContent: 'flex-end'}}>
                            <Button
                                component={Link}
                                to={`/rental-details/${offer.url}?startDateTime=${startDateTime}&endDateTime=${endDateTime}`}
                                endIcon={
                                    <Box
                                        component={'svg'}
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        width={24}
                                        height={24}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M17 8l4 4m0 0l-4 4m4-4H3"
                                        />
                                    </Box>
                                }
                            >
                                {t('common.more')}
                            </Button>
                        </CardActions>
                    </CardContent>
                </Box>
            </Box>
        </Grid>
    );
};

export default OfferCard;

import React, { ReactNode, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import Page from './components/Page';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'aos/dist/aos.css';
import { SnackbarProvider, useSnackbar } from "notistack";
import { useStore, store } from "./store/store";
import AppContextProvider from "./context/AppContextProvider";
import i18n from './i18n';
import { I18nextProvider } from "react-i18next";
import ResourceLoaderProvider from "./components/ResourceLoaderProvider";
import FeedbackForm from "./views/Feedback/FeedbackForm";
import FeedbackButton from "./views/Feedback/FeedbackButton";
import ScrollToTheTopButton from "./components/ScrollToTheTopButton";
import { Box } from "@mui/system";

const App = (): ReactNode => {
    const MyApp = () => {
        const { enqueueSnackbar } = useSnackbar();
        const errors = useStore((state: any) => state.errors);

        useEffect(() => {
            if (errors.length > 0) {
                const latestError = errors[errors.length - 1];
                enqueueSnackbar(latestError, { variant: 'error' });

                store.clearLastError();
            }
        }, [errors, enqueueSnackbar]);

        return (
            <Page>
                <Routes />
                <FeedbackForm />
                <Box sx={{ '& > :not(style)': { m: 1 } }}>
                    <FeedbackButton />
                    <ScrollToTheTopButton />
                </Box>
            </Page>
        );
    };

    return (
        <I18nextProvider i18n={i18n}>
            <SnackbarProvider maxSnack={3}>
                <Page>
                    <BrowserRouter>
                        <AppContextProvider>
                            <ResourceLoaderProvider>
                                <MyApp />
                            </ResourceLoaderProvider>
                        </AppContextProvider>
                    </BrowserRouter>
                </Page>
            </SnackbarProvider>
        </I18nextProvider>
    );
};

export default App;

import CommonApiService from "./CommonApiService";
import {ApiResponse, CheckoutResult, ReservationDetails} from "../types/api";

class CheckoutApi extends CommonApiService {

    sendCheckoutDetails(body: ReservationDetails): Promise<ApiResponse<any[]>> {
        return this.post<any[]>(`${this.baseUrl}/api/reservation/book`, body);
    }

    getStatus(reservationId: string): Promise<ApiResponse<CheckoutResult>> {
        return this.get<CheckoutResult>(`${this.baseUrl}/api/reservation/${reservationId}`);
    }
}

const checkoutApi = new CheckoutApi();
export default checkoutApi;
import CommonApiService from "./CommonApiService";
import {ApiResponse, Feedback} from "../types/api";

class FeedbackApi extends CommonApiService {

    sendFeedback(body: Feedback): Promise<ApiResponse<any[]>> {
        return this.post<any[]>(`${this.baseUrl}/api/feedback`, body);
    }
}

const feedbackApi = new FeedbackApi();
export default feedbackApi;
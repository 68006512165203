import React, { useState, useEffect } from 'react';
import { Fab, useTheme } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box } from '@mui/system';

const ScrollToTheTopButton: React.FC = () => {
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 70,
                right: 16,
                display: isVisible ? 'inline-flex' : 'none',
                zIndex: 1000,
            }}
        >
            <Fab
                color="secondary"
                size="medium"
                onClick={scrollToTop}
                sx={{
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
                    ':hover': {
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.4)',
                    },
                }}
            >
                <KeyboardArrowUpIcon />
            </Fab>
        </Box>
    );
};

export default ScrollToTheTopButton;

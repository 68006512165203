import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {SuccessImage} from "../Pyment/Image/SuccessImage";

interface FeedbackFormContentProps {
    onClose(): void,
}

const SuccessfullySentFeedback: React.FunctionComponent<FeedbackFormContentProps> = ({onClose}) => {
    const {t} = useTranslation();

    return (
        <Box
            paddingY={2}
            paddingX={4}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
        >
            <Box
                width="100%"
                display="flex"
                justifyContent="flex-end"
                paddingY={2}
            >
                <Box
                    component={'svg'}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    width={24}
                    height={24}
                    onClick={onClose}
                    sx={{cursor: 'pointer'}}
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                    />
                </Box>
            </Box>
            <Box
                data-aos={'fade-up'}
                marginBottom={{xs: 2, md: 4}}
            >
                {SuccessImage}
            </Box>
            <Box>
                <Typography
                    variant="h4"
                    align="center"
                    data-aos={'fade-up'}
                    gutterBottom
                    sx={{
                        fontWeight: 700,
                    }}
                    marginTop={4}
                >
                    {t('feedback.thanks')}
                </Typography>
            </Box>
            <Box>
                <Typography
                    align="center"
                    data-aos={'fade-up'}
                    gutterBottom
                    marginTop={4}
                >
                    {t('feedback.eachFeedback')}
                </Typography>
            </Box>
        </Box>
    );
};

export default SuccessfullySentFeedback;

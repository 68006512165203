import React, {ReactNode} from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {SuccessImage} from "../Image/SuccessImage";

const PaymentSuccess = (): ReactNode => {
    const {t} = useTranslation()

    return (
        <>
            <Box
                data-aos={'fade-up'}
                marginBottom={{xs: 2, md: 4}}
            >
                {SuccessImage}
            </Box>
            <Box>
                <Typography
                    variant="h4"
                    align={'center'}
                    data-aos={'fade-up'}
                    gutterBottom
                    sx={{
                        fontWeight: 700,
                    }}
                >
                    {t('payment.successTitle')}
                </Typography>
            </Box>
            <Box>
                <Typography
                    align={'center'}
                    data-aos={'fade-up'}
                    gutterBottom
                >
                    {t('payment.successMainText')}
                </Typography>
            </Box>
        </>
    );
};

export default PaymentSuccess;

import React, {ReactNode, useContext} from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import {Offer} from "../../types/api";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {AppContext, AppContextType} from "../../context/AppContextProvider";

interface CheckoutDetailsProps {
    offer: Offer,
}

const CustomMarkerInfo = ({offer}: CheckoutDetailsProps): ReactNode => {
    const {filtrationFormData}: AppContextType = useContext(AppContext);
    const {startDateTime, endDateTime} = filtrationFormData;

    const {t} = useTranslation()
    return (
        <Box
            component={Card}
        >
            <CardMedia
                title={offer.title}
                image={offer.media[0].url} // TODO: we should add 2 types of media 1) offer main cover and 2) additional offer photos
                sx={{
                    position: 'relative',
                    height: {xs: 240, sm: 340, md: 100},
                    overflow: 'hidden',
                }}
            >
            </CardMedia>
            <CardContent>
                <Box display={'flex'} alignItems={'center'} marginY={2}>
                    <Box
                        component={'svg'}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        width={16}
                        height={16}
                        marginRight={1}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                        />
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                    </Box>
                    <Typography variant={'subtitle2'} color="text.secondary">
                        {offer.offerAddress}
                    </Typography>
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                    <Box
                        component={'svg'}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        width={16}
                        height={16}
                        marginRight={1}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                        />
                    </Box>
                    <Typography variant={'subtitle2'} color="text.secondary">
                        {`${offer.spotLength} x ${offer.spotWidth} x ${offer.spotHeight}`}
                    </Typography>
                </Box>
                <CardActions sx={{justifyContent: 'flex-end'}}>
                    <Button
                        component={Link}
                        to={`/rental-details/${offer.url}?startDateTime=${startDateTime}&endDateTime=${endDateTime}`}
                        endIcon={
                            <Box
                                component={'svg'}
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                width={24}
                                height={24}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                                />
                            </Box>
                        }
                    >
                        {t('common.more')}
                    </Button>
                </CardActions>
            </CardContent>
        </Box>
    );
};

export default CustomMarkerInfo;
import React, {ReactNode, useEffect} from 'react';
import Main from "../../layouts/Main";
import Container from "../../components/Container";
import Grid from "@mui/material/Grid";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import {Box} from "@mui/system";
import OfferDetails from "../Host/components/OfferDetails";
import {Card} from "@mui/material";

enum Policies {
    PRIVACY_POLICY = "privacyPolicy",
    SELL_POLICY = "sellPolicy",
    SELL_OFFER = "sellOffer"
}

const LegalPolicies = (): ReactNode => {
    const {t} = useTranslation();
    const {policy} = useParams<{ policy: string }>();
    const navigate = useNavigate();

    useEffect(() => {
        if (!Object.values(Policies).includes(policy as Policies)) {
            navigate('/page-not-found');
        }
    }, [policy, navigate]);

    if (!Object.values(Policies).includes(policy as Policies)) {
        return null;
    }

    return (
        <Main colorInvert={true}>
            <Box bgcolor={'alternate.main'} paddingTop={1}>
                <Container>
                    <Grid container spacing={{xs: 2, md: 4}}>
                        <Grid item xs={12} md={12} marginTop={2} marginBottom={2}>
                            {/*<OfferDetails/>*/}

                            <Box id="main-page-search-form" padding={{xs: 3, sm: 6}} width={1} component={Card} boxShadow={1}
                                 data-aos="fade-up">
                                {policy === Policies.SELL_POLICY &&
                                    <Typography>
                                        <div dangerouslySetInnerHTML={{ __html: t(`userAgreement.text`) }} />
                                    </Typography>
                                }
                                {policy === Policies.SELL_OFFER &&
                                    <Typography>
                                        <div dangerouslySetInnerHTML={{ __html: t(`sellOffer.text`) }} />
                                    </Typography>
                                }
                                {policy === Policies.PRIVACY_POLICY &&
                                    <Typography>
                                        <div dangerouslySetInnerHTML={{ __html: t(`privacyPolicy.text`) }} />
                                    </Typography>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Main>
    );
};

export default LegalPolicies;

import React, {ReactNode, useContext, useState} from 'react';
import {Box, Button, Card, Checkbox, Divider, FormControlLabel, FormGroup, TextField, Tooltip} from '@mui/material';
import {useFormik} from 'formik';
import * as yup from 'yup';
import PhoneNumberInput from '../../../components/PhoneNumberInput';
import FileUploadWithDragAndDrop from './FileUploadWithDragAndDrop';
import {useTranslation} from 'react-i18next';
import GooglePlacesSearch_withBEApproach
    from '../../Main/components/GooglePlacesSearch/GooglePlacesSearch_withBEApproach';
import SingleSelector from '../../../components/SingleSelector';
import {HostOfferDetails} from '../../../types/api';
import dayjs from "dayjs";
import {v4 as uuidv4} from 'uuid';
import PolicyAgreementText from "../../Policies/PolicyAgreementText";
import hostApi from "../../../api/HostApi";
import {ResourceLoaderContext, ResourceLoaderContextType} from "../../../components/ResourceLoaderContext";
import {useNavigate} from "react-router-dom";
import Schedules from "./Schedules";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";

const latinWithDiacriticsRegex = /^[a-zA-Z\u00C0-\u017F ]*$/;

const validationSchema = yup.object({
    firstName: yup
        .string()
        .trim()
        .min(2, 'yupValidation.minFieldCharAmount')
        .max(50, 'yupValidation.maxFieldCharAmount')
        .matches(latinWithDiacriticsRegex, 'yupValidation.onlyLatinLetters'),
    email: yup
        .string()
        .trim()
        .nullable()
        .email('yupValidation.reqEmail')
        .notRequired(),
    phoneNumber: yup
        .string()
        .trim()
        .required('yupValidation.requiredFieldGeneral')
        .matches(
            /^\+?[1-9]{1}[0-9]{8,14}$/,
            'yupValidation.reqPhoneNumber'
        ),
    location: yup
        .string()
        .trim()
        .required('yupValidation.requiredFieldGeneral'),
    // bankAccountNumber: yup
    //     .string()
    //     .required('Bank account number is required')
    //     .transform((value) => value.replace(/\s+/g, ''))
    //     .matches(/^\d{26}$/, 'Bank account number must be exactly 26 digits'),
    description: yup
        .string()
        .nullable()
        .trim()
        .when((description, schema) => description
            ? schema
                .min(50, 'yupValidation.shortDescription')
                .max(1000, 'yupValidation.longDescription') : schema
        ),
    parkingType: yup
        .string()
        .trim(),
    spotLength: yup
        .number()
        .nullable()
        .typeError('yupValidation.priceNumber')
        .test('isValidLength', function (value) {
            if (value === null || value === undefined) {
                return true;
            }
            if (value < 1) {
                return this.createError({message: 'yupValidation.spotMinValue'});
            }
            if (value > 20) {
                return this.createError({message: 'yupValidation.spotMaxValue'});
            }
            return true;
        }),
    spotWidth: yup
        .number()
        .nullable()
        .typeError('yupValidation.priceNumber')
        .test('isValidLength', function (value) {
            if (value === null || value === undefined) {
                return true;
            }
            if (value < 1) {
                return this.createError({message: 'yupValidation.spotMinValue'});
            }
            if (value > 20) {
                return this.createError({message: 'yupValidation.spotMaxValue'});
            }
            return true;
        }),
    spotHeight: yup
        .number()
        .nullable()
        .typeError('yupValidation.priceNumber')
        .test('isValidLength', function (value) {
            if (value === null || value === undefined) {
                return true;
            }
            if (value < 1) {
                return this.createError({message: 'yupValidation.spotMinValue'});
            }
            if (value > 20) {
                return this.createError({message: 'yupValidation.spotMaxValue'});
            }
            return true;
        }),
    photos: yup
        .array()
        .of(yup.mixed())
        .max(3, 'yupValidation.maxPhotos')
        .notRequired()
        .test('fileSize', 'yupValidation.totalFileSizeTooLarge', function (value: any) {
            const maxSize = 20 * 1024 * 1024; // Max total file size should be 20MB
            const totalSize = value.reduce((acc: any, file: any) => acc + (file.size || 0), 0);
            return totalSize <= maxSize;
        }),
    schedules: yup.array().of(
        yup.object().shape({
            startDateTime: yup
                .date()
                .required('yupValidation.requiredFieldGeneral'),
            endDateTime: yup
                .date()
                .required('yupValidation.requiredFieldGeneral')
                .min(yup.ref('startDateTime'), 'yupValidation.endDate')
                .test('is-not-equal', 'yupValidation.endDateDiff', function (value) {
                    const {startDateTime} = this.parent;
                    const start = dayjs(startDateTime);
                    const end = dayjs(value);
                    return !start.isSame(end, 'minute');
                })
                .test('min-step', 'Minimum step between start and end date is 30 minutes', function (value) {
                    const {startDateTime} = this.parent;
                    const start = dayjs(startDateTime);
                    const end = dayjs(value);
                    return end.diff(start, 'minutes') >= 30;
                }),
            price: yup
                .number()
                .required('yupValidation.price')
                .min(1, 'yupValidation.priceMin')
                .max(100, 'yupValidation.priceMax')
                .typeError('yupValidation.priceNumber')
                .test(
                    'maxTwoDecimalPlaces',
                    'yupValidation.priceMaxTwoDecimals',
                    (value) => {
                        if (value !== undefined && value !== null) {
                            return /^\d+(\.\d{1,2})?$/.test(value.toString());
                        }
                        return true;
                    }
                )
        })
    ).min(1, 'yupValidation.schedulesAtLeastOne')
});

const OfferDetails_v2 = (): ReactNode => {
    const {setLoaded}: ResourceLoaderContextType = useContext(ResourceLoaderContext);
    const navigate = useNavigate();
    const [accordionOpen, setAccordionOpen] = useState(false);

    const {t} = useTranslation();
    const initialValues: HostOfferDetails = {
        location: '',
        firstName: '',
        lastName: '',
        // bankAccountNumber: '',
        email: '',
        phoneNumber: '+48',
        placeId: '',
        parkingType: '',
        spotLength: null,
        spotWidth: null,
        spotHeight: null,
        lpgAllowed: false,
        chargerPresent: false,
        guarded: false,
        accessible: false,
        schedules: [{
            startDateTime: dayjs(),
            endDateTime: dayjs().add(1, 'day'),
            price: 1,
            slotId: uuidv4()
        }],
        photos: [],
        description: ''
    };

    const createFormData = (values: any): FormData => {
        const formData = new FormData();

        Object.entries<any>(values).forEach(([key, value]) => {
            if (key === 'photos') {
                value.forEach((file: File) => {
                    formData.append(key, file);
                });
            } else if (Array.isArray(value)) {
                value.forEach((item, index) => {
                    formData.append(`${key}[${index}]`, JSON.stringify(item));
                });
            } else if (value !== null) {
                formData.append(key, value);
            }
        });

        return formData;
    };

    const onSubmit = async (values: any) => {
        const errors = await formik.validateForm();
        if (Object.keys(errors).length === 0) {

            try {
                setLoaded(false);
                const formData = createFormData(values);
                await hostApi.createOffer(formData)
                setLoaded(true);
                navigate('/offer-created-successfully');

            } catch (error) {
                console.error('Error sending checkout details:', error);
            }
        } else {
            console.log('Form has errors:', errors);
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    const handleLocationChange = (newLocation: any | null) => {
        formik.setFieldValue('location', newLocation ? newLocation.description : '');
        formik.setFieldValue('placeId', newLocation ? newLocation.place_id : '');
    };

    const toggleAccordion = () => {
        setAccordionOpen(!accordionOpen);
    };

    return (
        <Box id="main-page-search-form" padding={{xs: 3, sm: 6}} width={1} component={Card} boxShadow={1}
             data-aos="fade-up">
            <Box marginBottom={4} display={'flex'} justifyContent={'center'}>
                <Typography fontWeight={800} variant={"h5"}>
                    {t('common.hostPageTitle')}
                </Typography>
            </Box>
            <form noValidate onSubmit={formik.handleSubmit}>
                <Box display="flex" flexDirection={{xs: 'column', md: 'row'}} marginTop={{xs: 2, md: 2}}>
                    <Box width={1} marginRight={{xs: 0, md: 2}}>
                        <GooglePlacesSearch_withBEApproach handleLocationChange={handleLocationChange} formik={formik}/>
                    </Box>
                </Box>
                <Schedules formik={formik}/>
                <Divider sx={{marginTop: {xs: 2, md: 4}, marginBottom: {xs: 2, md: 2}}}/>
                <Box display={'flex'} justifyContent={"center"}>
                    <Typography color={'text.secondary'}
                                variant="subtitle2">{t("common.sensitiveDataHint")}</Typography>
                </Box>
                <Box display="flex" marginTop={{xs: 2, md: 2}}>
                    <Tooltip title={t("common.sensitiveDataHint")} placement="bottom-start">
                        <Box width={1} marginRight={{xs: 0, md: 2}} marginBottom={{xs: 2, md: 0}}>
                            <TextField
                                label={t('share.contactPerson')}
                                variant="outlined"
                                name="firstName"
                                fullWidth
                                value={formik.values.firstName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                helperText={formik.touched.firstName && t(formik.errors.firstName || '')}
                            />
                        </Box>
                    </Tooltip>
                </Box>
                <Box display="flex" flexDirection={{xs: 'column', md: 'row'}} marginTop={{xs: 2, md: 2}}
                     marginBottom={2}>
                    <Tooltip title={t("common.sensitiveDataHint")} placement="bottom-start">
                        <Box width={1} marginRight={{xs: 0, md: 2}} marginBottom={{xs: 2, md: 0}}>
                            <PhoneNumberInput formik={formik}/>
                        </Box>
                    </Tooltip>
                    <Tooltip title={t("common.sensitiveDataHint")} placement="bottom-start">
                        <Box width={1} marginRight={{xs: 0, md: 2}} marginBottom={{xs: 2, md: 0}}>
                            <TextField
                                label="Email"
                                variant="outlined"
                                name="email"
                                fullWidth
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && t(formik.errors.email || '')}
                            />
                        </Box>
                    </Tooltip>
                </Box>
                <Divider sx={{marginTop: {xs: 2, md: 4}, marginBottom: {xs: 2, md: 2}}}/>
                <Accordion
                    expanded={accordionOpen}
                    onChange={toggleAccordion}
                    elevation={0}
                    sx={{
                        marginTop: accordionOpen ? 0 : 2,
                        '&:before': {
                            display: 'none',
                        }
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        sx={{
                            "&.Mui-expanded": {
                                minHeight: 0
                            },
                            "& .MuiAccordionSummary-content.Mui-expanded": {
                                margin: "12px 0"
                            }
                        }}
                    >
                        <Typography color={'text.secondary'}
                                    variant="subtitle2">{t("common.advancedFilters")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{padding: 0}}>
                        <Box display="flex" flexDirection={{xs: 'column', md: 'row'}} marginTop={{xs: 2, md: 2}}>
                            <SingleSelector formik={formik}/>
                            <Box width={1} marginRight={{xs: 0, md: 2}} marginBottom={{xs: 2, md: 0}}>
                                <Box display="flex" justifyContent="space-between">
                                    <Box sx={{flex: 1, marginRight: 2}}>
                                        <TextField
                                            value={formik.values.spotLength}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            name="spotLength"
                                            variant="outlined"
                                            label={t('common.length')}
                                            error={formik.touched.spotLength && Boolean(formik.errors.spotLength)}
                                            helperText={formik.touched.spotLength && t(formik.errors.spotLength || '')}
                                        />
                                    </Box>
                                    <Box sx={{flex: 1, margin: '0 2px'}}>
                                        <TextField
                                            value={formik.values.spotWidth}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            name="spotWidth"
                                            variant="outlined"
                                            label={t('common.width')}
                                            error={formik.touched.spotWidth && Boolean(formik.errors.spotWidth)}
                                            helperText={formik.touched.spotWidth && t(formik.errors.spotWidth || '')}
                                        />
                                    </Box>
                                    <Box sx={{flex: 1, marginLeft: 2}}>
                                        <TextField
                                            value={formik.values.spotHeight}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            name="spotHeight"
                                            variant="outlined"
                                            label={t('common.height')}
                                            error={formik.touched.spotHeight && Boolean(formik.errors.spotHeight)}
                                            helperText={formik.touched.spotHeight && t(formik.errors.spotHeight || '')}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection={{xs: 'column', md: 'row'}} marginTop={{xs: 2, md: 2}}>
                            <Box>
                                <FormGroup>
                                    <Box
                                        display={'flex'}
                                        flexDirection={{xs: 'column', sm: 'row'}}
                                    >
                                        <Box>
                                            <FormControlLabel control={
                                                <Checkbox
                                                    name={'lpgAllowed'}
                                                    checked={formik.values.lpgAllowed}
                                                    onChange={formik.handleChange}
                                                    inputProps={{'aria-label': 'controlled'}}
                                                />
                                            } label={t('common.gasPoweredSearch')}/>
                                        </Box>
                                        <Box>
                                            <FormControlLabel control={
                                                <Checkbox
                                                    name={'chargerPresent'}
                                                    checked={formik.values.chargerPresent}
                                                    onChange={formik.handleChange}
                                                    inputProps={{'aria-label': 'controlled'}}
                                                />
                                            } label={t('common.electricCarCharger')}/>
                                        </Box>
                                        <Box>
                                            <FormControlLabel control={
                                                <Checkbox
                                                    name={'guarded'}
                                                    checked={formik.values.guarded}
                                                    onChange={formik.handleChange}
                                                    inputProps={{'aria-label': 'controlled'}}
                                                />
                                            } label={t('common.guardedParking')}/>
                                        </Box>
                                        <Box>
                                            <FormControlLabel control={
                                                <Checkbox
                                                    name={'accessible'}
                                                    checked={formik.values.accessible}
                                                    onChange={formik.handleChange}
                                                    inputProps={{'aria-label': 'controlled'}}
                                                />
                                            } label={t('common.accessibleParking')}/>
                                        </Box>
                                    </Box>
                                </FormGroup>
                            </Box>
                        </Box>
                        <Divider sx={{marginTop: 4, marginBottom: 4}}/>
                        <Box display="flex" flexDirection={{xs: 'column', md: 'row'}} marginTop={{xs: 2, md: 2}}>
                            <TextField
                                label={t('share.description')}
                                multiline
                                rows={6}
                                variant="outlined"
                                color="primary"
                                size="medium"
                                name="description"
                                fullWidth
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.description && Boolean(formik.errors.description)}
                                helperText={formik.touched.description && t(formik.errors.description || '')}
                            />
                        </Box>
                        <Box display="flex" flexDirection={{xs: 'column', md: 'row'}} marginTop={{xs: 2, md: 2}}>
                            <FileUploadWithDragAndDrop formik={formik}/>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Box display="flex" flexDirection={{xs: 'column', md: 'row'}} marginTop={{xs: 2, md: 2}}>
                    <Button sx={{height: 54, whiteSpace: 'nowrap'}} variant="contained" color="primary" size="medium"
                            fullWidth type="submit">
                        {t('share.shareParking')}
                    </Button>
                </Box>
            </form>
            <PolicyAgreementText/>
        </Box>
    );
};

export default OfferDetails_v2;

const fetchOptions = {
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
    },
} as const;

export {
    fetchOptions,
};

import React, {ReactNode, useContext, useEffect, useState} from 'react';
import Main from "../../layouts/Main";
import Container from "../../components/Container";
import Grid from "@mui/material/Grid";
import Details from "./components/Details";
import Image from "./components/Image";
import {useLocation, useParams} from "react-router-dom";
import {Offer} from "../../types/api";
import CheckoutDetails from "../../blocks/CheckoutDetails";
import Divider from "@mui/material/Divider";
import {Box} from "@mui/system";
import parkingPropertyService from "../../api/ParkingPropertyApi";
import {ResourceLoaderContext, ResourceLoaderContextType} from "../../components/ResourceLoaderContext";
import dayjs from "dayjs";

const RentalDetailsView = (): ReactNode => {
    const {id} = useParams<{ id: string }>();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const startDateTime = searchParams.get('startDateTime');
    const endDateTime = searchParams.get('endDateTime');
    const {setLoaded}: ResourceLoaderContextType = useContext(ResourceLoaderContext);
    const [currentOfferDetails, setCurrentOfferDetails] = useState<Offer | null>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        const fetchOfferDetails = async () => {
            if (id) {
                setLoaded(false);
                try {
                    const offer = await parkingPropertyService
                        .getOfferById(
                            id,
                            dayjs(startDateTime),
                            dayjs(endDateTime)
                        );
                    setCurrentOfferDetails(offer.data as Offer);
                } catch (error) {
                    console.error("Error fetching offer details:", error);
                } finally {
                    setLoaded(true);
                }
            }
        };

        fetchOfferDetails();
    }, [id, startDateTime, endDateTime, setLoaded]);

    // TODO: this works shitty!!!!re-renders page and move first to
    //  the top of the page and only after that scrolls where I need
    const handleClick = () => {
        setIsOpen(true)
    }

    return (
        <>
            {currentOfferDetails &&
                <Main colorInvert={true}>
                    <Box paddingTop={1}>
                        <Container>
                            <Grid container spacing={{xs: 2, md: 4}}>
                                <Grid item xs={12} md={7}>
                                    <Image availableForBooking={currentOfferDetails.availableForBooking} media={currentOfferDetails?.media || []}/>
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <Details
                                        currentOfferDetails={currentOfferDetails}
                                        handleClick={handleClick}
                                    />
                                </Grid>
                                {isOpen &&
                                    <>
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <CheckoutDetails currentOfferDetails={currentOfferDetails}/>
                                        </Grid>
                                    </>
                                }
                            </Grid>
                        </Container>
                    </Box>
                </Main>}
        </>
    );
};

export default RentalDetailsView;

// import i18n from "i18next";
// import {initReactI18next} from "react-i18next";
// import enResource from './locales/en/en.json';
// import plResource from './locales/pl/pl.json';
//
// i18n
//     .use(initReactI18next)
//     .init({
//         resources: {
//             en: {
//                 translation: enResource,
//             },
//             pl: {
//                 translation: plResource,
//             }
//         },
//         lng: "pl",
//         fallbackLng: "pl",
//         interpolation: {
//             escapeValue: false
//         }
//     });
//
// export default i18n;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enResource from './locales/en/en.json';
import plResource from './locales/pl/pl.json';


import privacyPolicyEN from './locales/en/privacyPolicyEN.json';
import privacyPolicyPL from './locales/pl/privacyPolicyPL.json';
import userAgreementEN from './locales/en/userAgreementEN.json';
import userAgreementPL from './locales/pl/userAgreementPL.json';
import sellOfferPL from './locales/pl/sellOfferPL.json';
i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    ...enResource,
                    privacyPolicy: privacyPolicyEN,
                    userAgreement: userAgreementEN,
                },
            },
            pl: {
                translation: {
                    ...plResource,
                    privacyPolicy: privacyPolicyPL,
                    userAgreement: userAgreementPL,
                    sellOffer: sellOfferPL,
                },
            }
        },
        lng: "pl",
        fallbackLng: "pl",
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;

import CommonApiService from './CommonApiService';
import {ApiResponse, AutocompleteResponse} from "../types/api";


class GeocodeService extends CommonApiService {

    // TODO: we should create a specific TS Type instead of using "any"
    getPlacesPrediction(input: string): Promise<ApiResponse<AutocompleteResponse>> {
        const queryParams = new URLSearchParams({query: input}).toString();
        return this.get<AutocompleteResponse>(`${this.baseUrl}/api/geo/autocomplete?${queryParams}`);
    }
}

const geocodingService = new GeocodeService();
export default geocodingService;

import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import {debounce} from '@mui/material/utils';
import {ApiResponse, AutocompleteResponse} from '../../../../types/api';
import {PlaceType} from '../../../../types/componentTypes';
import geocodingService from '../../../../api/GeocodeService';
import {useTranslation} from 'react-i18next';
import {useLocation} from "react-router-dom";

interface Props {
    handleLocationChange(newValue: PlaceType | null): void;

    formik: any;
}

const GooglePlacesSearch_withBEApproach: React.FunctionComponent<Props> = ({
                                                                               handleLocationChange,
                                                                               formik,
                                                                           }) => {
    const {t} = useTranslation();
    const location = useLocation();
    const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
    const inputRef = React.useRef<HTMLInputElement>(null);

    const fetch = React.useMemo(
        () =>
            debounce(async (input: string, callback: (results?: readonly PlaceType[]) => void) => {
                await geocodingService.getPlacesPrediction(input).then((result: ApiResponse<AutocompleteResponse>) => {
                    if (result.success && result.data) {
                        callback(result.data.predictions);
                    }
                });
            }, 400),
        [],
    );

    React.useEffect(() => {
        let active = true;

        if (formik.values.location === '') {
            setOptions([]);
            return undefined;
        }

        fetch(formik.values.location, (results?: readonly PlaceType[]) => {
            if (active) {
                let newOptions: readonly PlaceType[] = [];

                if (results) {
                    newOptions = [...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [formik.values.location, fetch]);

    const handleAutocompleteChange = (event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        handleLocationChange(newValue);
    };

    const handleEnterKey = (event: any) => {
        if (event.key === 'Enter' && !options.some(option => option.description === formik.values.location)) {
            event.preventDefault();
            if (options.length > 0) {
                handleAutocompleteChange(event, options[0]);
                formik.setFieldValue('location', options[0].description);
                setTimeout(() => {
                    if (inputRef.current) {
                        inputRef.current.blur();
                    }
                });
            }
        }
    };

    const getLabel = () => {
        return location.pathname === '/' ? t('common.addCity') : t('common.addCityHost');
    }

    return (
        <Autocomplete
            fullWidth
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={options.find((option) => option.description === formik.values.location) || null}
            noOptionsText="No locations"
            onChange={handleAutocompleteChange}
            onInputChange={(event, newInputValue) => {
                formik.setFieldValue('location', newInputValue);
            }}
            onKeyDown={handleEnterKey}
            renderInput={(params) => (
                <Box width={1} marginRight={{xs: 0, md: 2}} >
                    <TextField
                        required
                        name="location"
                        {...params}
                        label={getLabel()}
                        fullWidth
                        value={formik.values.location}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.location && Boolean(formik.errors.location)}
                        helperText={formik.touched.location && t(formik.errors.location)}
                        inputRef={inputRef}
                    />
                </Box>

            )}
            renderOption={(props, option) => {
                const matches = option.structured_formatting.main_text_matched_substrings || [];

                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match: any) => [match.offset, match.offset + match.length]),
                );

                return (
                    <li {...props}>
                        <Grid container alignItems="center">
                            <Grid item sx={{display: 'flex', width: 44}}>
                                <LocationOnIcon sx={{color: 'text.secondary'}}/>
                            </Grid>
                            <Grid item sx={{width: 'calc(100% - 44px)', wordWrap: 'break-word'}}>
                                {parts.map((part, index) => (
                                    <Box key={index} component="span"
                                         sx={{fontWeight: part.highlight ? 'bold' : 'regular'}}>
                                        {part.text}
                                    </Box>
                                ))}
                                <Typography variant="body2" color="text.secondary">
                                    {option.structured_formatting.secondary_text}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
};

export default GooglePlacesSearch_withBEApproach;

import React, {ReactNode, useContext, useEffect, useState} from 'react';
import Box from '@mui/material/Box';

import {Hero} from './components';
import Main from "../../layouts/Main";
import Container from "../../components/Container";
import Search from "./components/Search/Search";
import {AppContext, AppContextType} from "../../context/AppContextProvider";
import NoFiltrationResult from "./components/FeaturedProperties/NoFiltrationResult";
import {useStore} from "../../store/store";
import parkingPropertyService from "../../api/ParkingPropertyApi";
import FeaturedPropertiesPaginated from "./components/FeaturedProperties/FeaturedPropertiesPaginated";

export const PropertiesContext = React.createContext({});

const MainView = (): ReactNode => {
    const {updateContext, filtrationResult, filtrationFormData}: AppContextType = useContext(AppContext);
    const errors = useStore(state => state.errors);

    const [searchPerformed, setSearchPerformed] = useState(false);

    const {content} = filtrationResult?.offers ?? {};

    useEffect(() => {
        const fetchData = async () => {
            const formDataCopy = Object.assign({}, filtrationFormData);
            formDataCopy.placeId = "";

            try {
                let allOffers = await parkingPropertyService.getFilteredPropositions(formDataCopy, 0);

                updateContext({
                    filtrationResult: allOffers.data
                });
            } catch (error) {
                console.error("Error fetching data", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (filtrationResult && Object.keys(filtrationResult).length > 0) {
            setSearchPerformed(true);
        }
    }, [filtrationResult]);

    return (
        <Main colorInvert={true}>
            <Hero/>
            <Box bgcolor={'alternate.main'}>
                <Container>
                    <Search/>
                </Container>
            </Box>
            {errors.length === 0 && searchPerformed && (
                <Box bgcolor={'alternate.main'}>
                    {content.length > 0 ? (
                        <Container>
                            <PropertiesContext.Provider value={{isOpen: filtrationResult}}>
                                <FeaturedPropertiesPaginated/>
                            </PropertiesContext.Provider>
                        </Container>
                    ) : (
                        <Box
                            maxWidth={{sm: 720, md: 1236}}
                            width={1}
                            margin={'0 auto'}
                            paddingX={2}
                            paddingBottom={{xs: 4, sm: 6, md: 8}}
                        >
                            <PropertiesContext.Provider value={{isOpen: filtrationResult}}>
                                <NoFiltrationResult/>
                            </PropertiesContext.Provider>
                        </Box>
                    )}
                </Box>
            )}

        </Main>
    );
};

export default MainView;

import {CostDetails} from "../types/api";

export const isPriceWithPromo = (costDetails: CostDetails) => {
    const {
        currency,
        cost,
        fee,
        total,
        costAfterPromo,
        feeAfterPromo,
        totalAfterPromo
    } = costDetails;

    return costAfterPromo < cost || feeAfterPromo < fee;
};
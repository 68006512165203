import React, {ReactNode} from 'react';
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import {Box, Divider} from "@mui/material";
import {useTheme} from "@mui/material/styles";

const PolicyAgreementText = (): ReactNode => {
    const {t} = useTranslation();
    const theme = useTheme();

    return (
        <>
            <Box marginY={4} marginX={{xs: -3, sm: -6}}>
                <Divider/>
            </Box>

            <Box>
                <Typography component="p" variant="body2" align="left">
                    {t('common.agreeToTerms')}

                    <Box
                        component="a"
                        href="/legal-policy/privacyPolicy"
                        color={theme.palette.text.primary}
                        fontWeight={'700'}
                    >
                        {t('common.privacyPolicy')}
                    </Box>
                    ,{' '}
                    <Box
                        component="a"
                        href="/legal-policy/sellPolicy"
                        color={theme.palette.text.primary}
                        fontWeight={'700'}
                    >
                        {t('common.sellPolicy')}
                    </Box>{' '}
                    {t('common.and')}
                    <Box
                        component="a"
                        href="/legal-policy/sellOffer"
                        color={theme.palette.text.primary}
                        fontWeight={'700'}
                    >
                        {t('common.sellOffer')}
                    </Box>
                    .
                </Typography>
            </Box>
        </>
    );
};

export default PolicyAgreementText;

// import React from 'react';
// import {ResourceLoaderContext} from '../../components/ResourceLoaderContext';
// import {useLoadScript} from "@react-google-maps/api";
//
// const GOOGLE_MAPS_API_KEY = 'AIzaSyAPBi1-43oRRIpAx_rJvQX6jRZA5MWJvJI';
//
// interface Props {
//     children: React.ReactNode;
// }
//
// const GoogleMapsProvider: React.FC<Props> = ({children}) => {
//     const {isLoaded, loadError} = useLoadScript({
//         googleMapsApiKey: GOOGLE_MAPS_API_KEY,
//         id: 'google-maps',
//         libraries: ['places'],
//     });
//
//     if (loadError) {
//         console.error("Error loading Google Maps:", loadError);
//     }
//
//     return (
//         <ResourceLoaderContext.Provider value={{isLoaded, loadError}}>
//             {children}
//         </ResourceLoaderContext.Provider>
//     );
// };
//
// export default GoogleMapsProvider;

import React, { useState, useEffect } from 'react';
import { useLoadScript } from "@react-google-maps/api";
import {ResourceLoaderContextType, ResourceLoaderContext} from "./ResourceLoaderContext";

const GOOGLE_MAPS_API_KEY = 'AIzaSyAPBi1-43oRRIpAx_rJvQX6jRZA5MWJvJI';

interface Props {
    children: React.ReactNode;
}

const ResourceLoaderProvider: React.FC<Props> = ({ children }) => {
    const [isGoogleMapsLoaded, setGoogleMapsLoaded] = useState(false);
    const [isLoaded, setLoaded] = useState(true);
    const [loadError, setLoadError] = useState<Error | undefined>(undefined);

    const { isLoaded: mapsLoaded, loadError: mapsLoadError } = useLoadScript({
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
        id: 'google-maps',
        libraries: ['places'],
    });

    useEffect(() => {
        if (mapsLoaded) {
            setGoogleMapsLoaded(true);
        }
        if (mapsLoadError) {
            setLoadError(mapsLoadError);
        }
    }, [mapsLoaded, mapsLoadError]);

    const contextValue: ResourceLoaderContextType = {
        isGoogleMapsLoaded,
        setGoogleMapsLoaded,
        isLoaded,
        setLoaded,
        loadError,
        setLoadError,
    };

    return (
        <ResourceLoaderContext.Provider value={contextValue}>
            {children}
        </ResourceLoaderContext.Provider>
    );
};

export default ResourceLoaderProvider;

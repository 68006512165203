import * as React from 'react';
import dayjs, {Dayjs} from 'dayjs';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import Box from "@mui/material/Box";
import {MobileDateTimePicker} from "@mui/x-date-pickers";
import {useTranslation} from "react-i18next";
import {Typography} from "@mui/material";

interface Props {
    handleStartDateChange(startDateTime: Dayjs | null): void,

    handleEndDateChange(endDateTime: Dayjs | null): void,

    formik: any
}

const CustomDateRangePicker:
    React.FunctionComponent<Props> = ({
                                          handleStartDateChange,
                                          handleEndDateChange,
                                          formik
                                      }) => {
    const {t} = useTranslation()

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
                display={'flex'}
                flexDirection={{xs: 'column', sm: 'row'}}
            >
                <Box width={1}
                     marginRight={{xs: 0, md: 2}}
                     marginBottom={{xs: 2, md: 0}}
                >
                    <MobileDateTimePicker
                        name={'startDateTime'}
                        sx={{width: '100%'}}
                        minDate={dayjs()}
                        label={t('common.startDateHost')}
                        value={formik.values.startDateTime}
                        onChange={(newValue) => handleStartDateChange(newValue)}
                        views={['year', 'month', 'day', 'hours', 'minutes']}
                        ampm={false}
                        format="DD-MM-YYYY HH:mm"
                    />
                    {formik.touched.startDateTime && formik.errors.startDateTime && (
                        <Typography paddingLeft={2} marginTop={1} color="error" variant="caption">
                            {t(formik.errors.startDateTime)}
                        </Typography>
                    )}
                </Box>
                <Box width={1}
                     marginBottom={{xs: 2, md: 0}}
                >
                    <MobileDateTimePicker
                        name={'endDateTime'}
                        sx={{width: '100%'}}
                        minDate={formik.values.startDateTime || dayjs()}
                        label={t('common.endDateHost')}
                        value={formik.values.endDateTime}
                        onChange={(newValue) => handleEndDateChange(newValue)}
                        views={['year', 'month', 'day', 'hours', 'minutes']}
                        ampm={false}
                        format="DD-MM-YYYY HH:mm"
                    />
                    {formik.touched.endDateTime && formik.errors.endDateTime && (
                        <Typography paddingLeft={2} marginTop={1} color="error" variant="caption">
                            {t(formik.errors.endDateTime)}
                        </Typography>
                    )}
                </Box>
            </Box>
        </LocalizationProvider>
    );
}
export default CustomDateRangePicker;

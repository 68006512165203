import CommonApiService from "./CommonApiService";
import {ApiResponse, FiltrationBody, Offer} from "../types/api";
import {Dayjs} from "dayjs";

class ParkingPropertyApi extends CommonApiService {

    getFilteredPropositions(body: FiltrationBody, page: number): Promise<ApiResponse<any[]>> {
        const pageSize: number = 12;

        return this.post<any[]>(`${this.baseUrl}/api/offer/search?page=${page}&size=${pageSize}`, body);
    }

    getOfferById(id: string, startDateTime: Dayjs, endDateTime: Dayjs): Promise<ApiResponse<Offer>> {
        const baseURL = `${this.baseUrl}/api/offer/`;
        const url = new URL(`${baseURL}${id}`, document.location.href);

        if(startDateTime.isValid() && endDateTime.isValid()){
            const params = new URLSearchParams({
                startDateTime: startDateTime.toString(),
                endDateTime: endDateTime.toString(),
            });
            url.search = params.toString();
        }

        return this.get<Offer>(url.toString());
    }
}

const parkingPropertyService = new ParkingPropertyApi();
export default parkingPropertyService;
import React, {ReactNode, useCallback, useEffect, useState} from 'react';
import Main from "../../layouts/Main";
import Container from "../../components/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import Button from "@mui/material/Button";
import {useNavigate, useParams} from "react-router-dom";
import checkoutApi from "../../api/CheckoutApi";
import {ApiResponse, CheckoutResult, CheckoutStatus} from "../../types/api";
import PaymentSuccess from "./components/PaymentSuccess";
import PaymentFail from "./components/PaymentFail";
import PaymentInProgress from "./components/PaymentInProgress";
import dayjs from "dayjs";

const PaymentResult = (): ReactNode => {
    const {reservationId} = useParams<{ reservationId: string }>();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [checkoutResult, setCheckoutResult] = useState<CheckoutResult | null>(null);

    const fetchPaymentStatus = useCallback(async () => {
        try {
            if (reservationId) {
                const response = await checkoutApi.getStatus(reservationId) as ApiResponse<CheckoutResult>;

                if (response.success && response.data) {
                    setCheckoutResult(response.data);
                } else {
                    console.error('Error fetching payment status:', response);
                }
            } else {
                console.log('No reservationId');
            }
        } catch (error) {
            console.error('Error fetching payment status:', error);
        }
    }, [reservationId]);


    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            if (isMounted) await fetchPaymentStatus();
        };
        fetchData();
        return () => {
            isMounted = false;
        };
    }, [reservationId]);

    useEffect(() => {
        if (checkoutResult?.status === CheckoutStatus.LOCKED) {
            const intervalId = setInterval(fetchPaymentStatus, 5000);
            return () => clearInterval(intervalId);
        }
    }, [checkoutResult?.status]);

    const handleClickToMainPage = () => {
        navigate('/');
    };

    return (
        <Main colorInvert={true}>
            <Box bgcolor={'alternate.main'} paddingTop={1}>
                <Container>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {checkoutResult?.status === CheckoutStatus.PAYED && <PaymentSuccess/>}
                        {checkoutResult?.status === CheckoutStatus.UNPAID && <PaymentFail/>}
                        {checkoutResult?.status === CheckoutStatus.LOCKED && <PaymentInProgress/>}

                        <Box marginTop={2} marginBottom={1}>
                            <Typography
                                fontWeight={600}
                                align={'center'}
                                color="text.secondary"
                            >
                                {t('payment.reservationDetailsTitle')}
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="left"
                        >
                            {
                                checkoutResult?.description &&
                                <Box display={'flex'} alignItems={'center'} marginY={2} maxWidth={600}>
                                    <Typography variant={'subtitle2'} color="text.secondary">
                                        {checkoutResult?.description}
                                    </Typography>
                                </Box>
                            }
                            {
                                checkoutResult?.startTime && checkoutResult?.endTime &&
                                <Box display={'flex'} alignItems={'center'}>
                                    <Box
                                        component={'svg'}
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="#000000"
                                        viewBox="0 0 24 24"
                                        width={16}
                                        height={16}
                                        marginRight={1}
                                    >
                                        <path d="M0 0h24v24H0V0z" fill="none"/>
                                        <path
                                            d="M7 11h2v2H7v-2zm14-5v14c0 1.1-.9 2-2 2H5c-1.11 0-2-.9-2-2l.01-14c0-1.1.88-2 1.99-2h1V2h2v2h8V2h2v2h1c1.1 0 2 .9 2 2zM5 8h14V6H5v2zm14 12V10H5v10h14zm-4-7h2v-2h-2v2zm-4 0h2v-2h-2v2z"/>
                                    </Box>
                                    <Typography variant={'subtitle2'} color="text.secondary">
                                        {`${dayjs(checkoutResult?.startTime).format('DD-MM-YYYY HH:mm')} -
                                    ${dayjs(checkoutResult?.endTime).format('DD-MM-YYYY HH:mm')}`}
                                    </Typography>
                                </Box>
                            }
                            {
                                checkoutResult?.offerAddress &&
                                <Box display={'flex'} alignItems={'center'} marginY={2}>
                                    <Box
                                        component={'svg'}
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        width={16}
                                        height={16}
                                        marginRight={1}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                        />
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                    </Box>
                                    <Typography variant={'subtitle2'} color="text.secondary">
                                        {checkoutResult?.offerAddress}
                                    </Typography>
                                </Box>
                            }
                        </Box>
                        <Box
                            display="flex"
                            flexDirection={{xs: 'column', md: 'row'}}
                            marginTop={{xs: 2, md: 5}}
                        >
                            <Button
                                sx={{height: 54, whiteSpace: 'nowrap'}}
                                variant="contained"
                                color="primary"
                                size="medium"
                                fullWidth
                                type={'submit'}
                                onClick={handleClickToMainPage}
                            >
                                {t('common.backToMainPage')}
                            </Button>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Main>
    );
};

export default PaymentResult;